import { GET_USER, GET_USERS, GET_ERRORS, LOADING_USER } from "./types";
import axios from "axios";
import { toast } from "react-toastify";

// Get Users
export const getUsers = () => (dispatch) => {
  axios
    .get(`https://sandbox-api2.pentone.id/api/users/admin/all`)
    .then((res) => dispatch({ type: GET_USERS, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Get User by id
export const getUser = (id) => (dispatch) => {
  axios
    .get(`https://sandbox-api2.pentone.id/api/users/admin/user/${id}`)
    .then((res) => dispatch({ type: GET_USER, payload: res.data }))
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Update Status User
export const updateStatusUser = (undanganData) => {
  console.log("test");
  axios
    .post(
      `https://sandbox-api2.pentone.id/api/users/editOrder/${undanganData.idOrder}`,
      undanganData
    )
    .then((res) => {
      console.log("test suc");
      console.log(res.data);
      toast.success("Perubahan telah disimpan!");
    })
    .catch((err) => {
      console.log("test err");
      console.log(err.response);
      toast.error("Terjadi error. Perubahan tidak disimpan.");
    });
};

export const getStatistic = () => (dispatch) => {
  dispatch({ type: LOADING_USER, payload: true });
  axios
    .get(`https://sandbox-api2.pentone.id/api/users/statistic`)
    .then((res) => {
      dispatch({ type: GET_USERS, payload: res.data });
      dispatch({ type: LOADING_USER, payload: false });
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};
