import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createProduct } from "../../actions/productActions";
import { getColors } from "../../actions/colorActions";
import { getDesigners } from "../../actions/designerActions";
import { getCategories } from "../../actions/categoryActions";
import { ToastContainer } from "react-toastify";

class CreateProduct extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      designer: "",
      description: "",
      set: "",
      size: "",
      printing: "",
      category: "",
      color: [],
      tags: "",
      colorCategory: [],
      hargaSoftCoverTier1: 0,
      hargaSoftCoverTier2: 0,
      hargaSoftCoverTier3: 0,
      hargaSoftCoverTier4: 0,
      hargaSoftCoverTier5: 0,
      hargaHardCoverTier1: 0,
      hargaHardCoverTier2: 0,
      hargaHardCoverTier3: 0,
      hargaHardCoverTier4: 0,
      hargaHardCoverTier5: 0,
      gambar1: "",
      gambar2: "",
      gambar3: ""
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAdminAuthenticated) {
      this.props.history.push("/login");
    }
    this.props.getColors();
    this.props.getCategories();
    this.props.getDesigners();
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeFile = e => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  onChangeCheckBox = e => {
    let array;

    if (e.target.name === "color") {
      array = this.state.color;
    } else {
      array = this.state.colorCategory;
    }

    if (array.indexOf(e.target.value) === -1) {
      array.push(e.target.value);
    } else {
      array = array.filter(test => test !== e.target.value);
    }

    this.setState({ [e.target.name]: array });
  };

  onSubmit = e => {
    e.preventDefault();
    const newProduct = new FormData();

    newProduct.append("name", this.state.name);
    newProduct.append("designer", this.state.designer);
    newProduct.append("description", this.state.description);
    newProduct.append("set", this.state.set);
    newProduct.append("size", this.state.size);
    newProduct.append("printing", this.state.printing);
    newProduct.append("category", this.state.category);
    newProduct.append("color", this.state.color);
    newProduct.append("tags", this.state.tags);
    newProduct.append("colorCategory", this.state.colorCategory);
    newProduct.append("gambar1", this.state.gambar1);
    newProduct.append("gambar2", this.state.gambar2);
    newProduct.append("gambar3", this.state.gambar3);
    newProduct.append("hargaSoftCoverTier1", this.state.hargaSoftCoverTier1);
    newProduct.append("hargaSoftCoverTier2", this.state.hargaSoftCoverTier2);
    newProduct.append("hargaSoftCoverTier3", this.state.hargaSoftCoverTier3);
    newProduct.append("hargaSoftCoverTier4", this.state.hargaSoftCoverTier4);
    newProduct.append("hargaSoftCoverTier5", this.state.hargaSoftCoverTier5);
    newProduct.append("hargaHardCoverTier1", this.state.hargaHardCoverTier1);
    newProduct.append("hargaHardCoverTier2", this.state.hargaHardCoverTier2);
    newProduct.append("hargaHardCoverTier3", this.state.hargaHardCoverTier3);
    newProduct.append("hargaHardCoverTier4", this.state.hargaHardCoverTier4);
    newProduct.append("hargaHardCoverTier5", this.state.hargaHardCoverTier5);

    this.props.createProduct(newProduct);
  };

  render() {
    const { colors } = this.props.color;
    const { categories } = this.props.category;
    const { designers } = this.props.designer;

    if (categories.length > 0 && colors.length > 0 && designers.length > 0) {
      return (
        <div className="card">
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover={false}
          />
          <div className="card-header text-center">
            <h1 className="p-0 m-0">Create New Product</h1>
          </div>
          <div className="card-body">
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  name="name"
                  type="text"
                  className="form-control input-create"
                  placeholder="Input Product Name"
                  onChange={this.onChange}
                  value={this.state.name}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="name">Designer</label>
                <select
                  name="designer"
                  id="designer"
                  className="form-control"
                  onChange={this.onChange}
                  value={this.state.designer}
                >
                  {designers.map((d, i) => (
                    <option value={d._id}>{d.name}</option>
                  ))}
                </select>
              </div>
              <p>Category</p>
              <div className="form-group px-5">
                <div className="row">
                  {categories.map(c => (
                    <div className="col-md-2" key={c._id}>
                      <input
                        type="radio"
                        name="category"
                        className="form-check-input input-create"
                        id={c._id}
                        value={c._id}
                        onChange={this.onChange}
                        required
                      />
                      <label htmlFor={c._id}>{c.name}</label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="set">Set</label>
                <input
                  type="text"
                  className="form-control input-create"
                  name="set"
                  placeholder="Input Product Set"
                  onChange={this.onChange}
                  value={this.state.set}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="size">Size</label>
                <input
                  type="text"
                  className="form-control input-create"
                  name="size"
                  placeholder="Input Product Size"
                  onChange={this.onChange}
                  value={this.state.size}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="description">Description</label>
                <input
                  type="text"
                  className="form-control input-create"
                  name="description"
                  placeholder="Input Product Description"
                  onChange={this.onChange}
                  value={this.state.description}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="tags">Tags</label>
                <input
                  type="text"
                  className="form-control input-create"
                  name="tags"
                  placeholder="Input Product Tags"
                  onChange={this.onChange}
                  value={this.state.tags}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="printing">Printing</label>
                <input
                  type="text"
                  className="form-control input-create"
                  name="printing"
                  placeholder="Input Product Printing"
                  onChange={this.onChange}
                  value={this.state.printing}
                  required
                />
              </div>
              <p>Colors</p>
              <div className="form-group px-5">
                <div className="row">
                  {colors.map(c => (
                    <div className="col-md-1" key={c._id}>
                      <input
                        type="checkbox"
                        name="color"
                        className="form-check-input"
                        id={c._id}
                        value={c._id}
                        onChange={this.onChangeCheckBox}
                      />
                      <label>{c.name}</label>
                    </div>
                  ))}
                </div>
              </div>
              <p>Color Category</p>
              <div className="form-group px-5">
                <div className="row">
                  {colors.map(c => (
                    <div className="col-md-1" key={c._id}>
                      <input
                        type="checkbox"
                        name="colorCategory"
                        className="form-check-input"
                        id={c._id}
                        value={c._id}
                        onChange={this.onChangeCheckBox}
                      />
                      <label>{c.name}</label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="form-group">
                <p>Harga Soft Cover</p>
                <div className="row">
                  <div className="col">
                    Tier 1 (1000++<small>/pcs</small>)
                  </div>
                  <div className="col">
                    Tier 2 (700-999<small>/pcs</small>)
                  </div>
                  <div className="col">
                    Tier 3 (500-699<small>/pcs</small>)
                  </div>
                  <div className="col">
                    Tier 4 (300-499<small>/pcs</small>)
                  </div>
                  <div className="col">
                    Tier 5 (200-299<small>/pcs</small>)
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <input
                      type="number"
                      name="hargaSoftCoverTier1"
                      className="form-control input-create"
                      value={this.state.hargaSoftCoverTier1}
                      onChange={this.onChange}
                      required
                    />
                  </div>
                  <div className="col">
                    <input
                      type="number"
                      name="hargaSoftCoverTier2"
                      className="form-control input-create"
                      value={this.state.hargaSoftCoverTier2}
                      onChange={this.onChange}
                      required
                    />
                  </div>
                  <div className="col">
                    <input
                      type="number"
                      name="hargaSoftCoverTier3"
                      className="form-control input-create"
                      value={this.state.hargaSoftCoverTier3}
                      onChange={this.onChange}
                      required
                    />
                  </div>
                  <div className="col">
                    <input
                      type="number"
                      name="hargaSoftCoverTier4"
                      className="form-control input-create"
                      value={this.state.hargaSoftCoverTier4}
                      onChange={this.onChange}
                      required
                    />
                  </div>
                  <div className="col">
                    <input
                      type="number"
                      name="hargaSoftCoverTier5"
                      className="form-control input-create"
                      value={this.state.hargaSoftCoverTier5}
                      onChange={this.onChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <p>Harga Hard Cover</p>
                <div className="row">
                  <div className="col">
                    Tier 1 (1000++<small>/pcs</small>)
                  </div>
                  <div className="col">
                    Tier 2 (700-999<small>/pcs</small>)
                  </div>
                  <div className="col">
                    Tier 3 (500-699<small>/pcs</small>)
                  </div>
                  <div className="col">
                    Tier 4 (300-499<small>/pcs</small>)
                  </div>
                  <div className="col">
                    Tier 5 (200-299<small>/pcs</small>)
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <input
                      type="number"
                      name="hargaHardCoverTier1"
                      className="form-control input-create"
                      value={this.state.hargaHardCoverTier1}
                      onChange={this.onChange}
                      required
                    />
                  </div>
                  <div className="col">
                    <input
                      type="number"
                      name="hargaHardCoverTier2"
                      className="form-control input-create"
                      value={this.state.hargaHardCoverTier2}
                      onChange={this.onChange}
                      required
                    />
                  </div>
                  <div className="col">
                    <input
                      type="number"
                      name="hargaHardCoverTier3"
                      className="form-control input-create"
                      value={this.state.hargaHardCoverTier3}
                      onChange={this.onChange}
                      required
                    />
                  </div>
                  <div className="col">
                    <input
                      type="number"
                      name="hargaHardCoverTier4"
                      className="form-control input-create"
                      value={this.state.hargaHardCoverTier4}
                      onChange={this.onChange}
                      required
                    />
                  </div>
                  <div className="col">
                    <input
                      type="number"
                      name="hargaHardCoverTier5"
                      className="form-control input-create"
                      value={this.state.hargaHardCoverTier5}
                      onChange={this.onChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col">Gambar 1</div>
                  <div className="col">Gambar 2</div>
                  <div className="col">Gambar 3</div>
                </div>
                <div className="row">
                  <div className="col">
                    <input
                      type="file"
                      name="gambar1"
                      id="gambar1"
                      className="form-control input-create"
                      onChange={this.onChangeFile}
                      required
                    />
                  </div>
                  <div className="col">
                    <input
                      type="file"
                      name="gambar2"
                      id="gambar2"
                      className="form-control input-create"
                      onChange={this.onChangeFile}
                      required
                    />
                  </div>
                  <div className="col">
                    <input
                      type="file"
                      name="gambar3"
                      id="gambar3"
                      className="form-control input-create"
                      onChange={this.onChangeFile}
                      required
                    />
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-primary btn-block input-create"
                disabled={this.props.loading}
              >
                {this.props.loading ? (
                  <div
                    class="spinner-border spinner-border-sm text-light"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
        </div>
      );
    } else {
      return (
        <div className="my-5 py-5">
          <div className="progress my-5">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
              aria-valuenow="100"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      );
    }
  }
}

CreateProduct.propTypes = {
  createProduct: PropTypes.func.isRequired,
  getColors: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  color: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  category: state.category,
  color: state.color,
  loading: state.product.loading,
  designer: state.designer
});

export default connect(mapStateToProps, {
  createProduct,
  getColors,
  getCategories,
  getDesigners
})(CreateProduct);
