import React, { Component } from "react";
import { connect } from "react-redux";
import { getGalleryArticles } from "../../actions/galleryActions";

class galleryArticles extends Component {
  constructor() {
    super();
    this.state = {
      mode: "table"
    };
  }
  componentDidMount() {
    if (!this.props.auth.isAdminAuthenticated) {
      this.props.history.push("/login");
    }
    this.props.getGalleryArticles();
  }
  changeView = () => {
    if (this.state.mode === "table") {
      this.setState({ mode: "image" });
    } else {
      this.setState({ mode: "table" });
    }
  };
  render() {
    const { articles } = this.props.gallery;

    if (articles.length > 0) {
      if (this.state.mode === "table") {
        let i = 0;
        return (
          <React.Fragment>
            <button className="btn btn-primary" onClick={this.changeView}>
              Change View
            </button>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>No</th>
                  <th>File Name</th>
                  <th>Link</th>
                </tr>
              </thead>
              <tbody>
                {articles.map(article => (
                  <tr key={i++}>
                    <td>{i}</td>
                    <td>{article}</td>
                    <td>
                      <a
                        href={`https://www.sandbox-api2.pentone.id/assets/img/articles/${article}`}
                        target="_blank"
                      >{`https://www.sandbox-api2.pentone.id/assets/img/articles/${article}`}</a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <button className="btn btn-primary" onClick={this.changeView}>
              Change View
            </button>
            <div className="row">
              {articles.map(article => (
                <div className="col-md-2 mx-auto">
                  <img
                    src={`https://sandbox-api2.pentone.id/assets/img/articles/${article}`}
                    alt=""
                    className="img-fluid"
                  />
                  <p>{article}</p>
                </div>
              ))}
            </div>
          </React.Fragment>
        );
      }
    } else {
      return <h1>wait a sec</h1>;
    }
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  gallery: state.gallery
});

export default connect(
  mapStateToProps,
  { getGalleryArticles }
)(galleryArticles);
