import React, { Component } from "react";
import { connect } from "react-redux";
import { updateWording } from "../../actions/wordingAction";
import isEmpty from "../../utils/is-empty";
import { ToastContainer } from "react-toastify";

class EditWording extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      bahasa: "indonesia",
      agama: "islam",
      style: "formal",
      konten: ""
    };
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeFile = e => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  componentDidMount() {
    if (!this.props.auth.isAdminAuthenticated) {
      this.props.history.push("/login");
    }

    if (isEmpty(this.props.wording.wording)) {
      window.location = "/wordings";
    }

    this.setState({
      name: this.props.wording.wording.name,
      bahasa: this.props.wording.wording.bahasa,
      style: this.props.wording.wording.style,
      agama: this.props.wording.wording.agama,
      konten: this.props.wording.wording.konten
    });
  }

  onSubmit = e => {
    e.preventDefault();

    // const newWording = {};
    const newWording = new FormData();

    newWording.append("name", this.state.name);
    newWording.append("bahasa", this.state.bahasa);
    newWording.append("agama", this.state.agama);
    newWording.append("style", this.state.style);
    newWording.append("konten", this.state.konten);

    console.log(this.state);
    this.props.updateWording(this.state, this.props.wording.wording._id);
  };

  render() {
    if (isEmpty(this.props.wording.wording)) {
      return (
        <div className="my-5 py-5">
          <div className="progress my-5">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
              aria-valuenow="100"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="card">
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover={false}
          />
          <div className="card">
            <div className="card-header text-center">
              <h1 className="p-0 m-0">Create New Wording</h1>
            </div>
            <div className="card-body">
              <img
                src={`https://sandbox-api2.pentone.id/assets/img/wordings/${
                  this.props.wording.wording.konten
                }`}
                alt=""
                className="img-fluid"
              />
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder="Enter Wording Name"
                    value={this.state.name}
                    onChange={this.onChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="name">Agama</label>
                  <select
                    name="agama"
                    className="form-control"
                    onChange={this.onChange}
                    required
                  >
                    <option
                      value="islam"
                      selected={
                        this.props.wording.wording.agama === "islam"
                          ? true
                          : false
                      }
                    >
                      Islam
                    </option>
                    <option
                      value="lainnya"
                      selected={
                        this.props.wording.wording.agama === "lainnya"
                          ? true
                          : false
                      }
                    >
                      Lainnya
                    </option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="name">Bahasa</label>
                  <select
                    name="bahasa"
                    className="form-control"
                    onChange={this.onChange}
                    required
                  >
                    <option
                      value="indonesia"
                      selected={
                        this.props.wording.wording.bahasa === "indonesia"
                          ? true
                          : false
                      }
                    >
                      Indonesia
                    </option>
                    <option
                      value="inggris"
                      selected={
                        this.props.wording.wording.bahasa === "inggris"
                          ? true
                          : false
                      }
                    >
                      Inggris
                    </option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="name">Style</label>
                  <select
                    name="style"
                    className="form-control"
                    onChange={this.onChange}
                    required
                  >
                    <option
                      value="formal"
                      selected={
                        this.props.wording.wording.style === "formal"
                          ? true
                          : false
                      }
                    >
                      Formal
                    </option>
                    <option
                      value="nonFormal"
                      selected={
                        this.props.wording.wording.style === "nonFormal"
                          ? true
                          : false
                      }
                    >
                      Non Formal
                    </option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="konten">Konten</label>
                  <input
                    type="file"
                    name="konten"
                    className="form-control"
                    onChange={this.onChangeFile}
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  disabled={this.props.wording.loading}
                >
                  {this.props.wording.loading ? (
                    <div
                      className="spinner-border spinner-border-sm text-light"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  wording: state.wording
});

export default connect(
  mapStateToProps,
  { updateWording }
)(EditWording);
