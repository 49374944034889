import React, { Component } from "react";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import { postGalleryArticles } from "../../actions/galleryActions";
import { ToastContainer } from "react-toastify";

class galleryArticlesAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: []
    };
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    this.setState({ image: this.state.image.concat(acceptedFiles) });
  };

  deleteImage = index => {
    this.setState({
      image: this.state.image.filter(
        element => element !== this.state.image[index]
      )
    });
  };

  onSubmit = () => {
    console.log(`image : ${this.state.image}`);
    const image = new FormData();
    this.state.image.forEach(i => image.append("files", i));
    this.props.postGalleryArticles(image);
  };

  render() {
    let x = -1;
    console.log(this.state.image);
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover={false}
        />
        <h1>Add Article Images</h1>
        <button
          className="btn btn-primary"
          onClick={this.onSubmit}
          disabled={this.props.gallery.loading}
        >
          {this.props.gallery.loading ? (
            <div
              class="spinner-border spinner-border-sm text-light"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          ) : (
            "Upload"
          )}
        </button>
        <Dropzone onDrop={this.onDrop}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={classNames("dropzone form-control form-control-lg", {
                  "dropzone--isActive": isDragActive
                })}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop files here...</p>
                ) : (
                  <p>
                    Try dropping some files here, or click to select files to
                    upload.
                  </p>
                )}
              </div>
            );
          }}
        </Dropzone>
        <div className="row">
          <ul className="list-group">
            {this.state.image.map(i => (
              <React.Fragment key={x++}>
                <li className="list-group-item w-100">
                  {i.name} ({i.size})Kb
                  <span
                    className="btn btn-sm btn-danger ml-2"
                    onClick={this.deleteImage.bind(this, x)}
                  >
                    X
                  </span>{" "}
                </li>
              </React.Fragment>
            ))}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  gallery: state.gallery
});

export default connect(
  mapStateToProps,
  { postGalleryArticles }
)(galleryArticlesAdd);
