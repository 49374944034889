import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getDesigners,
  getDesigner,
  deleteDesigner
} from "../../actions/designerActions";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import isEmpty from "../../utils/is-empty";

class Designers extends Component {
  componentDidMount() {
    if (!this.props.auth.isAdminAuthenticated) {
      this.props.history.push("/login");
    }
    this.props.getDesigners();
  }

  onDelete = id => {
    if (window.confirm("Are you sure to delete this designer?")) {
      this.props.deleteDesigner(id);
    }
  };

  onEdit = (designer, e) => {
    this.props.getDesigner(designer);
    this.props.history.push(`designers-edit/${designer.slug}`);
  };

  render() {
    const { designers } = this.props.designer;
    if (!isEmpty(designers)) {
      return (
        <React.Fragment>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover={false}
          />
          <Link className="btn btn-success" to="designers-create">
            Create New Designer
          </Link>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Name</th>
                <th>Avatar</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {designers.map(designer => (
                <tr key={designer._id}>
                  <td>{designer.name}</td>
                  <td>
                    <img
                      className="img-fluid"
                      src={`https://sandbox-api2.pentone.id/assets/img/designers/avatar/${designer.avatar}`}
                      alt="Testing"
                      style={{ maxWidth: "100px" }}
                    />
                  </td>
                  <td className="px-0">
                    <button
                      className="btn btn-sm btn-warning mx-0"
                      onClick={this.onEdit.bind(this, designer)}
                    >
                      Edit
                    </button>
                  </td>
                  {/* <td className="px-0">
                    <button
                      className="btn btn-sm btn-danger mx-0"
                      onClick={this.onDelete.bind(this, designer._id)}
                      disabled={this.props.designer.loading}
                    >
                      {this.props.designer.loading ? (
                        <div
                          class="spinner-border spinner-border-sm text-light"
                          role="status"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                      ) : (
                        "Delete"
                      )}
                    </button>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </React.Fragment>
      );
    } else {
      return (
        <div className="my-5 py-5">
          <div className="progress my-5">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
              aria-valuenow="100"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  designer: state.designer,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getDesigners,
  getDesigner,
  deleteDesigner
})(Designers);
