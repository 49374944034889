export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCT = "GET_PRODUCT";
export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const LOADING_PRODUCT = "LOADING_PRODUCT";

export const GET_ARTICLES = "GET_ARTICLES";
export const GET_ARTICLE = "GET_ARTICLE";
export const CREATE_ARTICLE = "CREATE_ARTICLE";
export const UPDATE_ARTICLE = "UPDATE_ARTICLE";
export const DELETE_ARTICLE = "DELETE_ARTICLE";
export const LOADING_ARTICLE = "LOADING_ARTICLE";

export const GET_COLORS = "GET_COLORS";
export const GET_COLOR = "GET_COLOR";
export const CREATE_COLOR = "CREATE_COLOR";
export const UPDATE_COLOR = "UPDATE_COLOR";
export const DELETE_COLOR = "DELETE_COLOR";
export const LOADING_COLOR = "LOADING_COLOR";

export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORY = "GET_CATEGORY";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const LOADING_CATEGORY = "LOADING_CATEGORY";

export const GET_WORDINGS = "GET_WORDINGS";
export const GET_WORDING = "GET_WORDING";
export const CREATE_WORDING = "CREATE_WORDING";
export const UPDATE_WORDING = "UPDATE_WORDING";
export const DELETE_WORDING = "DELETE_WORDING";
export const LOADING_WORDING = "LOADING_WORDING";

export const GET_GALLERY_PRODUCTS = "GET_GALLERY_PRODUCTS";
export const GET_GALLERY_ARTICLES = "GET_GALLERY_ARTICLES";
export const POST_GALLERY_PRODUCTS = "POST_GALLERY_PRODUCTS";
export const POST_GALLERY_ARTICLES = "POST_GALLERY_ARTICLES";
export const LOADING_GALLERY = "LOADING_GALLERY";

export const GET_DESIGNERS = "GET_DESIGNERS";
export const GET_DESIGNER = "GET_DESIGNER";
export const CREATE_DESIGNER = "CREATE_DESIGNER";
export const UPDATE_DESIGNER = "UPDATE_DESIGNER";
export const DELETE_DESIGNER = "DELETE_DESIGNER";
export const LOADING_DESIGNER = "LOADING_DESIGNER";

export const GET_ADDITIONAL_ITEMS = "GET_ADDITIONAL_ITEMS";
export const GET_ADDITIONAL_ITEM = "GET_ADDITIONAL_ITEM";
export const CREATE_ADDITIONAL_ITEM = "CREATE_ADDITIONAL_ITEM";
export const UPDATE_ADDITIONAL_ITEM = "UPDATE_ADDITIONAL_ITEM";
export const DELETE_ADDITIONAL_ITEM = "DELETE_ADDITIONAL_ITEM";
export const LOADING_ADDITIONAL_ITEM = "LOADING_ADDITIONAL_ITEM";

export const GET_USERS = "GET_USERS";
export const GET_USER = "GET_USER";
export const LOADING_USER = "LOADING_USER";

export const GET_ERRORS = "GET_ERRORS";

export const SET_CURRENT_ADMIN = "SET_CURRENT_ADMIN";
