import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";

import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentAdmin, logoutAdmin } from "./actions/authActions";

import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import Dashboard from "./components/layout/Dashboard";

import Login from "./components/admin/Login";

import Products from "./components/product/Products";
import Product from "./components/product/Product";
import CreateProduct from "./components/product/CreateProduct";
import EditProduct from "./components/product/EditProduct";

import Articles from "./components/article/Articles";
import Article from "./components/article/Article";
import CreateArticle from "./components/article/CreateArticle";
import EditArticle from "./components/article/EditArticle";

import Categories from "./components/category/Categories";
import CreateCategory from "./components/category/CreateCategory";
import EditCategory from "./components/category/EditCategory";

import Colors from "./components/color/Colors";
import CreateColor from "./components/color/CreateColor";
import EditColor from "./components/color/EditColor";

import indexGallery from "./components/gallery/index";
import galleryProducts from "./components/gallery/galleryProducts";
import galleryProductsAdd from "./components/gallery/galleryProductsAdd";
import galleryArticles from "./components/gallery/galleryArticles";
import galleryArticlesAdd from "./components/gallery/galleryArticlesAdd";

import Wordings from "./components/wording/Wordings";
import CreateWording from "./components/wording/CreateWording";
import EditWording from "./components/wording/EditWording";

import Designers from "./components/designer/Designers";
import CreateDesigner from "./components/designer/CreateDesigner";
import EditDesigner from "./components/designer/EditDesigner";

import Users from "./components/users/Users";
import User from "./components/users/User";

import Statistic from "./components/statistic/Statistic";

import "react-toastify/dist/ReactToastify.css";

// Check for token
if (localStorage.jwtTokenAdmin) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtTokenAdmin);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtTokenAdmin);
  // Set user and isAdminAuthenticated
  store.dispatch(setCurrentAdmin(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutAdmin());
    // Redirect to login
    window.location.href = "/login";
  }
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <Navbar />
            <div className="container py-5">
              <Route exact path="/" component={Dashboard} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/products" component={Products} />
              <Route exact path="/products-create" component={CreateProduct} />
              <Route exact path="/products/:slug" component={Product} />
              <Route exact path="/products-edit/:id" component={EditProduct} />
              <Route exact path="/articles" component={Articles} />
              <Route exact path="/articles-create" component={CreateArticle} />
              <Route exact path="/articles/:id" component={Article} />
              <Route exact path="/articles-edit/:id" component={EditArticle} />
              <Route exact path="/categories" component={Categories} />
              <Route
                exact
                path="/categories-create"
                component={CreateCategory}
              />
              <Route
                exact
                path="/categories-edit/:id"
                component={EditCategory}
              />
              <Route exact path="/colors" component={Colors} />
              <Route exact path="/colors-create" component={CreateColor} />
              <Route exact path="/colors-edit/:id" component={EditColor} />
              <Route exact path="/gallery" component={indexGallery} />
              <Route
                exact
                path="/gallery/products"
                component={galleryProducts}
              />
              <Route
                exact
                path="/gallery/products/add"
                component={galleryProductsAdd}
              />
              <Route
                exact
                path="/gallery/articles"
                component={galleryArticles}
              />
              <Route
                exact
                path="/gallery/articles/add"
                component={galleryArticlesAdd}
              />
              <Route exact path="/users" component={Users} />
              <Route exact path="/users/:id" component={User} />
              <Route exact path="/wordings" component={Wordings} />
              <Route exact path="/wordings-create" component={CreateWording} />
              <Route
                exact
                path="/wordings-edit/:name"
                component={EditWording}
              />
              <Route exact path="/designers" component={Designers} />
              <Route
                exact
                path="/designers-create"
                component={CreateDesigner}
              />
              <Route
                exact
                path="/designers-edit/:slug"
                component={EditDesigner}
              />
              <Route exact path="/statistic" component={Statistic} />
            </div>
            <Footer />
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
