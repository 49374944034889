import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getProducts,
  deleteProduct,
  getProduct
} from "../../actions/productActions";
import isEmpty from "../../utils/is-empty";
import { ToastContainer } from "react-toastify";

class Products extends Component {
  constructor() {
    super();
    this.state = {
      sort: "name_asc"
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAdminAuthenticated) {
      this.props.history.push("/login");
    }
    this.props.getProducts();
  }

  onDelete = id => {
    if (window.confirm("Are you sure delete this product?")) {
      this.props.deleteProduct(id);
    }
  };

  sortNama = () => {
    if (this.state.sort !== "name_asc") {
      this.setState({ sort: "name_asc" });
    } else {
      this.setState({ sort: "name_desc" });
    }
  };

  sortDesigner = () => {
    if (this.state.sort !== "designer_asc") {
      this.setState({ sort: "designer_asc" });
    } else {
      this.setState({ sort: "designer_desc" });
    }
  };

  sortCategory = () => {
    if (this.state.sort !== "category_asc") {
      this.setState({ sort: "category_asc" });
    } else {
      this.setState({ sort: "category_desc" });
    }
  };

  onView = (product, e) => {
    this.props.getProduct(product);
    this.props.history.push(`products/${product.slug}`);
  };

  onEdit = (product, e) => {
    this.props.getProduct(product);
    this.props.history.push(`products-edit/${product.slug}`);
  };

  render() {
    const { products } = this.props.product;
    let compare;
    switch (this.state.sort) {
      case "name_asc":
        compare = (a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        };
        break;
      case "name_desc":
        compare = (a, b) => {
          if (a.name < b.name) return 1;
          if (a.name > b.name) return -1;
          return 0;
        };
        break;
      case "designer_asc":
        compare = (a, b) => {
          if (a.designer < b.designer) return -1;
          if (a.designer > b.designer) return 1;
          return 0;
        };
        break;
      case "designer_desc":
        compare = (a, b) => {
          if (a.designer < b.designer) return 1;
          if (a.designer > b.designer) return -1;
          return 0;
        };
        break;
      case "category_asc":
        compare = (a, b) => {
          if (a.category.name < b.category.name) return -1;
          if (a.category.name > b.category.name) return 1;
          return 0;
        };
        break;
      case "category_desc":
        compare = (a, b) => {
          if (a.category.name < b.category.name) return 1;
          if (a.category.name > b.category.name) return -1;
          return 0;
        };
        break;
      default:
        break;
    }

    if (!isEmpty(products)) {
      products.sort(compare);
      return (
        <div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover={false}
          />
          <Link to="/products-create" className="btn btn-success">
            Create New Product
          </Link>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>
                  Nama{" "}
                  <span
                    className="btn btn-sm btn-warning"
                    onClick={this.sortNama}
                  >
                    Sort
                  </span>
                </th>
                <th>
                  Designer{" "}
                  <span
                    className="btn btn-sm btn-warning"
                    onClick={this.sortDesigner}
                  >
                    Sort
                  </span>
                </th>
                <th>
                  Category{" "}
                  <span
                    className="btn btn-sm btn-warning"
                    onClick={this.sortCategory}
                  >
                    Sort
                  </span>
                </th>
                <th />
                <th />
              </tr>
            </thead>
            <tbody>
              {products.map(product => (
                <tr key={product._id}>
                  <td>{product.name}</td>
                  <td>{product.designer.name}</td>
                  <td>{product.category.name}</td>
                  <td className="py-1 m-0">
                    <img
                      src={`https://sandbox-api2.pentone.id/assets/img/products/${
                        product.image[0]
                      }`}
                      alt={product.image[0]}
                      className="img-fluid"
                      width="100px"
                    />
                  </td>
                  <td className="px-0">
                    <button
                      className="btn btn-sm mx-0 btn-info"
                      onClick={this.onView.bind(this, product)}
                    >
                      View
                    </button>
                  </td>
                  <td className="px-0">
                    <button
                      className="btn btn-sm mx-0 btn-warning"
                      onClick={this.onEdit.bind(this, product)}
                    >
                      Edit
                    </button>
                  </td>
                  <td className="px-0">
                    <button
                      onClick={this.onDelete.bind(this, product._id)}
                      className="btn btn-sm mx-0 btn-danger"
                      disabled={this.props.product.loading}
                    >
                      {this.props.product.loading ? "Sabar" : "Delete"}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    } else {
      return (
        <div className="my-5 py-5">
          <div className="progress my-5">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
              aria-valuenow="100"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      );
    }
  }
}

Products.propTypes = {
  getProducts: PropTypes.func.isRequired,
  deleteProduct: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  product: state.product,
  error: state.error
});

export default connect(
  mapStateToProps,
  { getProducts, deleteProduct, getProduct }
)(Products);
