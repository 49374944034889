import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createWording } from "../../actions/wordingAction";
import { ToastContainer } from "react-toastify";

class CreateWording extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      bahasa: "indonesia",
      agama: "islam",
      style: "formal",
      konten: ""
    };
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeFile = e => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  componentDidMount() {
    if (!this.props.auth.isAdminAuthenticated) {
      this.props.history.push("/login");
    }
  }

  onSubmit = e => {
    e.preventDefault();

    // const newWording = {};
    const newWording = new FormData();

    newWording.append("name", this.state.name);
    newWording.append("bahasa", this.state.bahasa);
    newWording.append("agama", this.state.agama);
    newWording.append("style", this.state.style);
    newWording.append("konten", this.state.konten);

    this.props.createWording(newWording);
  };

  render() {
    return (
      <div className="card">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover={false}
        />
        <div className="card">
          <div className="card-header text-center">
            <h1 className="p-0 m-0">Create New Wording</h1>
          </div>
          <div className="card-body">
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Enter Wording Name"
                  value={this.state.name}
                  onChange={this.onChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="name">Agama</label>
                <select
                  name="agama"
                  className="form-control"
                  onChange={this.onChange}
                  required
                >
                  <option value="islam">Islam</option>
                  <option value="lainnya">Lainnya</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="name">Bahasa</label>
                <select
                  name="bahasa"
                  className="form-control"
                  onChange={this.onChange}
                  required
                >
                  <option value="indonesia">Indonesia</option>
                  <option value="inggris">Inggris</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="name">Style</label>
                <select
                  name="style"
                  className="form-control"
                  onChange={this.onChange}
                  required
                >
                  <option value="formal">Formal</option>
                  <option value="nonFormal">Non Formal</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="konten">Konten</label>
                <input
                  type="file"
                  name="konten"
                  className="form-control"
                  onChange={this.onChangeFile}
                  required
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary btn-block"
                disabled={this.props.wording.loading}
              >
                {this.props.wording.loading ? (
                  <div
                    className="spinner-border spinner-border-sm text-light"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  wording: state.wording
});

export default connect(
  mapStateToProps,
  { createWording }
)(CreateWording);
