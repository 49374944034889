import {
  GET_PRODUCTS,
  GET_PRODUCT,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  LOADING_PRODUCT
} from "../actions/types";

const initialState = {
  product: {},
  products: [],
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        loading: false
      };
    case GET_PRODUCT:
      return {
        ...state,
        product: action.payload,
        loading: false
      };
    case CREATE_PRODUCT:
      return {
        ...state,
        loading: false
      };
    case UPDATE_PRODUCT:
      return {
        ...state,
        loading: false
      };
    case DELETE_PRODUCT:
      return {
        ...state,
        loading: false
      };
    case LOADING_PRODUCT:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
};
