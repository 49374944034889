import {
  GET_ARTICLES,
  GET_ARTICLE,
  GET_ERRORS,
  CREATE_ARTICLE,
  UPDATE_ARTICLE,
  DELETE_ARTICLE,
  LOADING_ARTICLE
} from "./types";
import axios from "axios";
import { toast } from "react-toastify";

// Get Articles
export const getArticles = () => dispatch => {
  dispatch(setArticleLoading(true));
  axios
    .get("https://sandbox-api2.pentone.id/api/articles?category=all")
    .then(res => {
      dispatch({ type: GET_ARTICLES, payload: res.data });
    })
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Get Article by id (WAIT)
export const getArticle = article => dispatch => {
  dispatch({ type: GET_ARTICLE, payload: article });
};

// Get Article by category
export const getArticlesByCategory = category => dispatch => {
  dispatch(setArticleLoading(true));
  axios
    .get(`https://sandbox-api2.pentone.id/api/articles?category=${category}`)
    .then(res => dispatch({ type: GET_ARTICLES, payload: res.data }))
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Create new Article
export const createArticle = newArticle => dispatch => {
  dispatch(setArticleLoading(true));
  axios
    .post("https://sandbox-api2.pentone.id/api/articles", newArticle)
    .then(res => {
      dispatch({ type: CREATE_ARTICLE, payload: res.data });
      toast.success("Article berhasil dibuat!");
    })
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Update Article by id
export const updateArticle = (newArticle, id) => dispatch => {
  dispatch(setArticleLoading(true));
  axios
    .post(`https://sandbox-api2.pentone.id/api/articles/${id}`, newArticle)
    .then(res => {
      dispatch({ type: UPDATE_ARTICLE, payload: res.data });
      toast.success("Article berhasil diperbarui!");
    })
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Delete Article by id
export const deleteArticle = id => dispatch => {
  dispatch(setArticleLoading(true));
  axios
    .delete(`https://sandbox-api2.pentone.id/api/articles/${id}`)
    .then(res => {
      dispatch({ type: DELETE_ARTICLE, payload: res.data });
      toast.success("Article berhasil dihapus!");
    })
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const setArticleLoading = loading => {
  return {
    type: LOADING_ARTICLE,
    payload: loading
  };
};
