import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getUser, updateStatusUser } from "../../actions/userActions";
import {
  getAdditionalItemByIdOrder,
  createAdditionalItem,
  updateAdditionalItem,
  deleteAdditionalItem
} from "../../actions/additionalItemActions";
import { isEmpty } from "../../utils/is-empty";
import axios from "axios";

class User extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      quantity: 0,
      total: 0,
      showModal: false,
      choosenAdditionalItem: {}
    };
  }

  onClickStatus = (status, e) => {
    // window.alert(`Status: ${status}`);

    const newData = {};
    newData.idUser = this.props.user.user._id;
    newData.idOrder = this.props.user.user.idOrder._id;
    if (status == "First Payment") {
      newData.tanggalDP = Date.now();
    }
    newData.status = status;

    updateStatusUser(newData);
  };

  async componentDidMount() {
    await this.props.getUser(this.props.match.params.id);
    let response = await axios.get(
      `https://sandbox-api2.pentone.id/api/users/admin/user/${this.props.match.params.id}`
    );
    let idOrder = response.data.idOrder._id;
    this.props.getAdditionalItemByIdOrder(idOrder);
  }

  async onAddAdditionalItem(e) {
    e.preventDefault();
    let newAdditionalItem = {
      name: this.state.name,
      quantity: this.state.quantity,
      total: this.state.total,
      idOrder: this.props.user.user.idOrder._id
    };
    await this.props.createAdditionalItem(newAdditionalItem);
    this.props.getAdditionalItemByIdOrder(this.props.user.user.idOrder._id);
  }

  async onSaveEditAdditionalItem(e) {
    e.preventDefault();
    let inputs = e.currentTarget.querySelectorAll("input");
    let newAdditionalItem = {};
    inputs.forEach(i => {
      newAdditionalItem[i.name] = i.value;
    });
    this.setState({ choosenAdditionalItem: {} });

    let id = newAdditionalItem.id;
    delete newAdditionalItem.id;

    await this.props.updateAdditionalItem(newAdditionalItem, id);
    await this.props.getAdditionalItemByIdOrder(
      this.props.user.user.idOrder._id
    );
  }

  async onDeleteAdditionalItem(id) {
    await this.props.deleteAdditionalItem(id);
    await this.props.getAdditionalItemByIdOrder(
      this.props.user.user.idOrder._id
    );
  }

  render() {
    const { user } = this.props.user;
    if (typeof user._id !== "undefined") {
      console.log(user);
      return (
        <div>
          <div className="row">
            <div className="col-2">
              <button
                className="btn btn-primary"
                onClick={this.onClickStatus.bind(this, "Hot Leads")}
              >
                Hot Leads
              </button>
            </div>
            <div className="col-2">
              <button
                className="btn btn-primary"
                onClick={this.onClickStatus.bind(this, "First Payment")}
              >
                First Payment
              </button>
            </div>
            <div className="col-2">
              <button
                className="btn btn-primary"
                onClick={this.onClickStatus.bind(this, "Preview Design")}
              >
                Preview Design
              </button>
            </div>
            <div className="col-2">
              <button
                className="btn btn-primary"
                onClick={this.onClickStatus.bind(this, "Naik Cetak")}
              >
                Naik Cetak
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="card shadow my-2">
                <div className="card-body">
                  <h1 className="display-4">Status Pesanan</h1>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th width="50%" />
                        <th width="50%" />
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Status</td>
                        <td>{user.idOrder.status}</td>
                      </tr>
                      <tr>
                        <td>ID Pembayaran DP</td>
                        <td>{user.idOrder.idTransactionDP}</td>
                      </tr>
                      <tr>
                        <td>Link Pembayaran DP</td>
                        <td>{user.idOrder.linkPembayaranDP}</td>
                      </tr>
                      <tr>
                        <td>ID Pembayaran Lunas</td>
                        <td>{user.idOrder.idTransactionLunas}</td>
                      </tr>
                      <tr>
                        <td>Link Pembayaran Lunas</td>
                        <td>{user.idOrder.linkPembayaranLunas}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="card shadow my-2">
                <div className="card-body">
                  <h1 className="display-4">Data User</h1>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th width="50%" />
                        <th width="50%" />
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Name</td>
                        <td>{user.name}</td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td>{user.email}</td>
                      </tr>
                      <tr>
                        <td>Tanggal Estimasi Pernikahan</td>
                        <td>
                          {new Date(user.estimasiPernikahan).getDate() +
                            "-" +
                            new Date(user.estimasiPernikahan).getMonth() +
                            "-" +
                            new Date(user.estimasiPernikahan).getFullYear()}
                        </td>
                      </tr>
                      <tr>
                        <td>Tanggal Daftar Akun</td>
                        <td>
                          {new Date(user.date).getDate() +
                            "-" +
                            new Date(user.date).getMonth() +
                            "-" +
                            new Date(user.date).getFullYear()}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="card shadow my-2">
                <div className="card-body">
                  <h1 className="display-4">Data Undangan</h1>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th width="50%" />
                        <th width="50%" />
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Nama Undangan</td>
                        <td>{user.idOrder.idProduct.name}</td>
                      </tr>
                      <tr>
                        <td>Tipe Undangan</td>
                        <td>{user.idOrder.idProduct.category}</td>
                      </tr>
                      <tr>
                        <td>Jumlah Undangan</td>
                        <td>{user.idOrder.quantity}</td>
                      </tr>
                      <tr>
                        <td>Jenis Kertas</td>
                        <td>{user.idOrder.idJenisKertas}</td>
                      </tr>
                      <tr>
                        <td>Sentuhan Akhir</td>
                        <td>{user.idOrder.sentuhanAkhir.join(", ")}</td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <h5>Produk Tambahan</h5>
                        </td>
                      </tr>
                      <tr>
                        <td>Buku Tamu</td>
                        <td>{user.idOrder.bukuTamu}</td>
                      </tr>
                      <tr>
                        <td>Bridesmaid Card</td>
                        <td>{user.idOrder.bridesmaidCard}</td>
                      </tr>
                      <tr>
                        <td>Bestman Card</td>
                        <td>{user.idOrder.bestmanCard}</td>
                      </tr>
                      <tr>
                        <td>Buku Pengajian</td>
                        <td>{user.idOrder.bukuPengajian}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="card shadow my-2">
                <div className="card-body">
                  <h1 className="display-4">Data Mempelai Wanita</h1>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th width="50%" />
                        <th width="50%" />
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Nama Lengkap</td>
                        <td>{user.idOrder.namaLengkapWanita}</td>
                      </tr>
                      <tr>
                        <td>Nama Panggilan</td>
                        <td>{user.idOrder.namaPanggilanWanita}</td>
                      </tr>
                      <tr>
                        <td>Nama Ibu</td>
                        <td>{user.idOrder.namaIbuWanita}</td>
                      </tr>
                      <tr>
                        <td>Nama Ayah</td>
                        <td>{user.idOrder.namaAyahWanita}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="card shadow my-2">
                <div className="card-body">
                  <h1 className="display-4">Data Mempelai Pria</h1>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th width="50%" />
                        <th width="50%" />
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Nama Lengkap</td>
                        <td>{user.idOrder.namaLengkapPria}</td>
                      </tr>
                      <tr>
                        <td>Nama Panggilan</td>
                        <td>{user.idOrder.namaPanggilanPria}</td>
                      </tr>
                      <tr>
                        <td>Nama Ibu</td>
                        <td>{user.idOrder.namaIbuPria}</td>
                      </tr>
                      <tr>
                        <td>Nama Ayah</td>
                        <td>{user.idOrder.namaAyahPria}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="card shadow my-2">
                <div className="card-body">
                  <h1 className="display-4">Data Detail Acara</h1>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th width="50%" />
                        <th width="50%" />
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Tanggal Akad Pernikahan</td>
                        <td>
                          {new Date(user.idOrder.tanggalAkad).getDate() +
                            "-" +
                            new Date(user.idOrder.tanggalAkad).getMonth() +
                            "-" +
                            new Date(user.idOrder.tanggalAkad).getFullYear()}
                        </td>
                      </tr>
                      <tr>
                        <td>Waktu Akad Pernikahan</td>
                        <td>{user.idOrder.waktuAkad}</td>
                      </tr>
                      <tr>
                        <td>Lokasi Akad/Pemberkatan</td>
                        <td>{user.idOrder.lokasiAkad}</td>
                      </tr>
                      <tr>
                        <td>Tanggal Resepsi</td>
                        <td>{user.idOrder.tanggalResepsi}</td>
                      </tr>
                      <tr>
                        <td>Waktu Resepsi</td>
                        <td>{user.idOrder.waktuResepsi}</td>
                      </tr>
                      <tr>
                        <td>Lokasi Resepsi</td>
                        <td>{user.idOrder.lokasiResepsi}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card shadow">
                <div className="card-body">
                  <h5>Additional Items</h5>
                  <form onSubmit={this.onAddAdditionalItem.bind(this)}>
                    <div className="row">
                      <div className="col-3">
                        <div className="form-group">
                          <label htmlFor="name">Name</label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            className="form-control"
                            value={this.state.name}
                            onChange={e =>
                              this.setState({ name: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          <label htmlFor="quantity">Quantity</label>
                          <input
                            type="number"
                            name="quantity"
                            id="quantity"
                            className="form-control"
                            value={this.state.quantity}
                            onChange={e =>
                              this.setState({ quantity: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          <label htmlFor="total">Price/pc</label>
                          <input
                            type="number"
                            name="total"
                            id="total"
                            className="form-control"
                            value={this.state.total}
                            onChange={e =>
                              this.setState({ total: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-3 py-4">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block h-100"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </form>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Nama</th>
                        <th>Jumlah</th>
                        <th>Price/pc</th>
                        <th>Total</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.additionalItem.loading ? (
                        <tr>
                          <td colSpan={4}>Loading</td>
                        </tr>
                      ) : (
                        this.props.additionalItem.additionalItems.map(
                          (ai, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{ai.name}</td>
                              <td>{ai.quantity}</td>
                              <td>{ai.total.toLocaleString()}</td>
                              <td>
                                {(ai.total * ai.quantity).toLocaleString()}
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  data-toggle="modal"
                                  data-target="#modalEdit"
                                  onClick={() =>
                                    this.setState({ choosenAdditionalItem: ai })
                                  }
                                >
                                  Edit
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={() =>
                                    this.onDeleteAdditionalItem(ai._id)
                                  }
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          )
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* MODAL */}
          <div
            className="modal fade"
            id="modalEdit"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="modalEditLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="modalEditLabel">
                    {this.state.choosenAdditionalItem.name}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form onSubmit={this.onSaveEditAdditionalItem.bind(this)}>
                    <div className="form-group">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        name="name"
                        defaultValue={this.state.choosenAdditionalItem.name}
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="quantity">Quantity</label>
                      <input
                        type="number"
                        name="quantity"
                        defaultValue={this.state.choosenAdditionalItem.quantity}
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="total">Price/pc</label>
                      <input
                        type="number"
                        name="total"
                        defaultValue={this.state.choosenAdditionalItem.total}
                        className="form-control"
                      />
                    </div>
                    <input
                      type="hidden"
                      name="id"
                      value={this.state.choosenAdditionalItem._id}
                    />
                    <button type="submit" className="btn btn-primary btn-block">
                      Save
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      console.log("loading...");
      return <div>Loading...</div>;
    }
  }
}

User.propTypes = {
  getUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.user,
  additionalItem: state.additionalItem
});

export default connect(mapStateToProps, {
  getUser,
  updateStatusUser,
  getAdditionalItemByIdOrder,
  createAdditionalItem,
  updateAdditionalItem,
  deleteAdditionalItem
})(User);
