import {
  GET_ARTICLES,
  GET_ARTICLE,
  CREATE_ARTICLE,
  GET_ERRORS,
  UPDATE_ARTICLE,
  DELETE_ARTICLE,
  LOADING_ARTICLE
} from "../actions/types";

const initialState = {
  article: {},
  articles: [],
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ARTICLES:
      return {
        ...state,
        articles: action.payload,
        loading: false
      };
    case GET_ARTICLE:
      return {
        ...state,
        article: action.payload,
        loading: false
      };
    case CREATE_ARTICLE:
      return {
        ...state,
        loading: false
      };
    case UPDATE_ARTICLE:
      return {
        ...state,
        article: action.payload,
        loading: false
      };
    case DELETE_ARTICLE:
      return {
        ...state,
        loading: false
      };
    case GET_ERRORS:
      return {
        ...state,
        loading: false
      };
    case LOADING_ARTICLE:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
};
