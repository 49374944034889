import {
  GET_COLORS,
  GET_ERRORS,
  CREATE_COLOR,
  UPDATE_COLOR,
  DELETE_COLOR,
  GET_COLOR,
  LOADING_COLOR
} from "./types";
import axios from "axios";
import { toast } from "react-toastify";

// Get Colors
export const getColors = () => dispatch => {
  axios
    .get("https://sandbox-api2.pentone.id/api/colors")
    .then(res => dispatch({ type: GET_COLORS, payload: res.data }))
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Get Color by ID (WAIT)
export const getColor = color => dispatch => {
  dispatch({ type: GET_COLOR, payload: color });
};

// Get Colors
export const createColor = newColor => dispatch => {
  dispatch(setColorLoading(true));
  axios
    .post("https://sandbox-api2.pentone.id/api/colors", newColor)
    .then(res => {
      dispatch({ type: CREATE_COLOR, payload: res.data });
      toast.success("Color berhasil dibuat!");
    })
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Update Color by id
export const updateColor = (newColor, id) => dispatch => {
  dispatch(setColorLoading(true));
  axios
    .post(`https://sandbox-api2.pentone.id/api/colors/${id}`, newColor)
    .then(res => {
      dispatch({ type: UPDATE_COLOR, payload: res.data });
      toast.success("Color berhasil diupdate!");
    })
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Delete Color by id
export const deleteColor = id => dispatch => {
  dispatch(setColorLoading(true));
  axios
    .delete(`https://sandbox-api2.pentone.id/api/colors/${id}`)
    .then(res => {
      dispatch({ type: DELETE_COLOR, payload: res.data });
      toast.success("Color berhasil dihapus!");
    })
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const setColorLoading = loading => {
  return {
    type: LOADING_COLOR,
    payload: loading
  };
};
