import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getColor, updateColor } from "../../actions/colorActions";
import isEmpty from "../../utils/is-empty";
import { ToastContainer } from "react-toastify";

class EditColor extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      code: ""
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAdminAuthenticated) {
      this.props.history.push("/login");
    }
    if (isEmpty(this.props.color.color)) {
      window.location = "/colors";
    }
    this.setState(this.props.color.color);
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const newColor = {};

    newColor.name = this.state.name;
    newColor.code = this.state.code;

    this.props.updateColor(newColor, this.props.color.color._id);
  };

  render() {
    const { color } = this.props.color;

    if (!isEmpty(color)) {
      return (
        <div className="card">
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover={false}
          />
          <div className="card-header text-center">
            <h1 className="p-0 m-0">Create New Color</h1>
          </div>
          <div className="card-body">
            <div className="row">
              <div
                className="col-md-2 mx-auto text-center"
                style={{
                  backgroundColor: this.state.code,
                  border: "2px solid black"
                }}
              >
                Color Preview
              </div>
            </div>
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="form-control input-create"
                  placeholder="Enter Color Name"
                  value={this.state.name}
                  onChange={this.onChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="code">Code</label>
                <input
                  type="text"
                  name="code"
                  id="code"
                  className="form-control input-create"
                  placeholder="Enter Color Code"
                  value={this.state.code}
                  onChange={this.onChange}
                  required
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary btn-block input-create"
                disabled={this.props.color.loading}
              >
                {this.props.color.loading ? (
                  <div
                    class="spinner-border spinner-border-sm text-light"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
        </div>
      );
    } else {
      return (
        <div className="my-5 py-5">
          <div className="progress my-5">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
              aria-valuenow="100"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      );
    }
  }
}

EditColor.propTypes = {
  getColor: PropTypes.func.isRequired,
  updateColor: PropTypes.func.isRequired,
  color: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  color: state.color
});

export default connect(
  mapStateToProps,
  { getColor, updateColor }
)(EditColor);
