import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getProductById, updateProduct } from "../../actions/productActions";
import { getColors } from "../../actions/colorActions";
import { getCategories } from "../../actions/categoryActions";
import isEmpty from "../../utils/is-empty";
import { ToastContainer } from "react-toastify";

class EditProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      designer: "",
      description: "",
      set: "",
      size: "",
      printing: "",
      category: "",
      color: [],
      tags: "",
      colorCategory: [],
      hargaSoftCoverTier1: 0,
      hargaSoftCoverTier2: 0,
      hargaSoftCoverTier3: 0,
      hargaSoftCoverTier4: 0,
      hargaSoftCoverTier5: 0,
      hargaHardCoverTier1: 0,
      hargaHardCoverTier2: 0,
      hargaHardCoverTier3: 0,
      hargaHardCoverTier4: 0,
      hargaHardCoverTier5: 0,
      gambar1: "",
      gambar2: "",
      gambar3: ""
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAdminAuthenticated) {
      this.props.history.push("/login");
    }

    if (isEmpty(this.props.product.product)) {
      window.location = "/products";
    }

    let { product } = this.props.product;
    let arrayColor = [];
    let arrayColorCategory = [];
    product.color.forEach(color => {
      arrayColor.push(color._id);
    });
    product.colorCategory.forEach(color => {
      arrayColorCategory.push(color._id);
    });
    this.setState({
      name: product.name,
      designer: product.designer,
      description: product.description,
      set: product.set,
      size: product.size,
      printing: product.printing,
      category: product.category._id,
      color: arrayColor,
      tags: product.tags,
      colorCategory: arrayColorCategory,
      hargaSoftCoverTier1: product.hargaSoftCoverTier1,
      hargaSoftCoverTier2: product.hargaSoftCoverTier2,
      hargaSoftCoverTier3: product.hargaSoftCoverTier3,
      hargaSoftCoverTier4: product.hargaSoftCoverTier4,
      hargaSoftCoverTier5: product.hargaSoftCoverTier5,
      hargaHardCoverTier1: product.hargaHardCoverTier1,
      hargaHardCoverTier2: product.hargaHardCoverTier2,
      hargaHardCoverTier3: product.hargaHardCoverTier3,
      hargaHardCoverTier4: product.hargaHardCoverTier4,
      hargaHardCoverTier5: product.hargaHardCoverTier5,
      gambar1: product.image[0],
      gambar2: product.image[1],
      gambar3: product.image[2]
    });
    this.props.getCategories();
    this.props.getColors();
  }

  onSubmit = e => {
    e.preventDefault();
    const updateProduct = new FormData();

    updateProduct.append("name", this.state.name);
    updateProduct.append("designer", this.state.designer);
    updateProduct.append("description", this.state.description);
    updateProduct.append("set", this.state.set);
    updateProduct.append("size", this.state.size);
    updateProduct.append("printing", this.state.printing);
    updateProduct.append("category", this.state.category);
    updateProduct.append("color", this.state.color);
    updateProduct.append("tags", this.state.tags);
    updateProduct.append("colorCategory", this.state.colorCategory);

    updateProduct.append("gambar1", this.state.gambar1);
    updateProduct.append("gambar2", this.state.gambar2);
    updateProduct.append("gambar3", this.state.gambar3);

    updateProduct.append("hargaSoftCoverTier1", this.state.hargaSoftCoverTier1);
    updateProduct.append("hargaSoftCoverTier2", this.state.hargaSoftCoverTier2);
    updateProduct.append("hargaSoftCoverTier3", this.state.hargaSoftCoverTier3);
    updateProduct.append("hargaSoftCoverTier4", this.state.hargaSoftCoverTier4);
    updateProduct.append("hargaSoftCoverTier5", this.state.hargaSoftCoverTier5);
    updateProduct.append("hargaHardCoverTier1", this.state.hargaHardCoverTier1);
    updateProduct.append("hargaHardCoverTier2", this.state.hargaHardCoverTier2);
    updateProduct.append("hargaHardCoverTier3", this.state.hargaHardCoverTier3);
    updateProduct.append("hargaHardCoverTier4", this.state.hargaHardCoverTier4);
    updateProduct.append("hargaHardCoverTier5", this.state.hargaHardCoverTier5);

    this.props.updateProduct(updateProduct, this.props.product.product._id);
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeCheckBox = e => {
    let array;

    if (e.target.name === "color") {
      array = this.state.color;
    } else {
      array = this.state.colorCategory;
    }

    if (array.indexOf(e.target.value) === -1) {
      array.push(e.target.value);
    } else {
      array = array.filter(test => test !== e.target.value);
    }

    this.setState({ [e.target.name]: array });
  };

  onChangeFile = e => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  render() {
    const { categories } = this.props.category;
    const { colors } = this.props.color;

    if (!isEmpty(this.props.product.product) && categories.length > 0) {
      return (
        <React.Fragment>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover={false}
          />
          <div className="row mb-3">
            <div className="col-md-3 mx-auto">
              <img
                src={`https://sandbox-api2.pentone.id/assets/img/products/${
                  this.props.product.product.image[0]
                }`}
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-md-3 mx-auto">
              <img
                src={`https://sandbox-api2.pentone.id/assets/img/products/${
                  this.props.product.product.image[1]
                }`}
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-md-3 mx-auto">
              <img
                src={`https://sandbox-api2.pentone.id/assets/img/products/${
                  this.props.product.product.image[2]
                }`}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>

          <div className="card">
            <div className="card-header text-center">
              <h1 className="p-0 m-0">Edit Product</h1>
            </div>
            <div className="card-body">
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    name="name"
                    type="text"
                    className="form-control"
                    placeholder="Input Product Name"
                    value={this.state.name}
                    onChange={this.onChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="name">Designer</label>
                  <input
                    name="designer"
                    type="text"
                    className="form-control"
                    placeholder="Input Designer Name"
                    onChange={this.onChange}
                    value={this.state.designer}
                    required
                  />
                </div>
                <p>Category</p>
                <div className="form-group px-5">
                  <div className="row">
                    {categories.map(c => (
                      <div className="col-md-2" key={c._id}>
                        <input
                          type="radio"
                          name="category"
                          className="form-check-input"
                          id={c._id}
                          value={c._id}
                          checked={this.state.category === c._id ? true : false}
                          onChange={this.onChange}
                          required
                        />
                        <label htmlFor={c._id}>{c.name}</label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="set">Set</label>
                  <input
                    type="text"
                    className="form-control"
                    name="set"
                    placeholder="Input Product Set"
                    value={this.state.set}
                    onChange={this.onChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="size">Size</label>
                  <input
                    type="text"
                    className="form-control"
                    name="size"
                    placeholder="Input Product Size"
                    value={this.state.size}
                    onChange={this.onChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="description">Description</label>
                  <input
                    type="text"
                    className="form-control"
                    name="description"
                    placeholder="Input Product Description"
                    value={this.state.description}
                    onChange={this.onChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="tags">Tags</label>
                  <input
                    type="text"
                    className="form-control"
                    name="tags"
                    placeholder="Input Product Tags"
                    value={this.state.tags}
                    onChange={this.onChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="printing">Printing</label>
                  <input
                    type="text"
                    className="form-control"
                    name="printing"
                    placeholder="Input Product Printing"
                    value={this.state.printing}
                    onChange={this.onChange}
                    required
                  />
                </div>
                <p>Colors</p>
                <div className="form-group px-5">
                  <div className="row">
                    {colors.map(c => (
                      <div className="col-md-1" key={c._id}>
                        <input
                          type="checkbox"
                          name="color"
                          className="form-check-input"
                          id={c._id}
                          value={c._id}
                          onChange={this.onChangeCheckBox}
                          checked={
                            this.state.color.indexOf(c._id) !== -1
                              ? true
                              : false
                          }
                        />
                        <label>{c.name}</label>
                      </div>
                    ))}
                  </div>
                </div>
                <p>Color Category</p>
                <div className="form-group px-5">
                  <div className="row">
                    {colors.map(c => (
                      <div className="col-md-1" key={c._id}>
                        <input
                          type="checkbox"
                          name="colorCategory"
                          className="form-check-input"
                          id={c._id}
                          value={c._id}
                          onChange={this.onChangeCheckBox}
                          checked={
                            this.state.colorCategory.indexOf(c._id) !== -1
                              ? true
                              : false
                          }
                        />
                        <label>{c.name}</label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="form-group">
                  <p>Harga Soft Cover</p>
                  <div className="row">
                    <div className="col">Tier 1</div>
                    <div className="col">Tier 2</div>
                    <div className="col">Tier 3</div>
                    <div className="col">Tier 4</div>
                    <div className="col">Tier 5</div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <input
                        type="number"
                        name="hargaSoftCoverTier1"
                        className="form-control"
                        value={this.state.hargaSoftCoverTier1}
                        onChange={this.onChange}
                        required
                      />
                    </div>
                    <div className="col">
                      <input
                        type="number"
                        name="hargaSoftCoverTier2"
                        className="form-control"
                        value={this.state.hargaSoftCoverTier2}
                        onChange={this.onChange}
                        required
                      />
                    </div>
                    <div className="col">
                      <input
                        type="number"
                        name="hargaSoftCoverTier3"
                        className="form-control"
                        value={this.state.hargaSoftCoverTier3}
                        onChange={this.onChange}
                        required
                      />
                    </div>
                    <div className="col">
                      <input
                        type="number"
                        name="hargaSoftCoverTier4"
                        className="form-control"
                        value={this.state.hargaSoftCoverTier4}
                        onChange={this.onChange}
                        required
                      />
                    </div>
                    <div className="col">
                      <input
                        type="number"
                        name="hargaSoftCoverTier5"
                        className="form-control"
                        value={this.state.hargaSoftCoverTier5}
                        onChange={this.onChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <p>Harga Hard Cover</p>
                  <div className="row">
                    <div className="col">Tier 1</div>
                    <div className="col">Tier 2</div>
                    <div className="col">Tier 3</div>
                    <div className="col">Tier 4</div>
                    <div className="col">Tier 5</div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <input
                        type="number"
                        name="hargaHardCoverTier1"
                        className="form-control"
                        value={this.state.hargaHardCoverTier1}
                        onChange={this.onChange}
                        required
                      />
                    </div>
                    <div className="col">
                      <input
                        type="number"
                        name="hargaHardCoverTier2"
                        className="form-control"
                        value={this.state.hargaHardCoverTier2}
                        onChange={this.onChange}
                        required
                      />
                    </div>
                    <div className="col">
                      <input
                        type="number"
                        name="hargaHardCoverTier3"
                        className="form-control"
                        value={this.state.hargaHardCoverTier3}
                        onChange={this.onChange}
                        required
                      />
                    </div>
                    <div className="col">
                      <input
                        type="number"
                        name="hargaHardCoverTier4"
                        className="form-control"
                        value={this.state.hargaHardCoverTier4}
                        onChange={this.onChange}
                        required
                      />
                    </div>
                    <div className="col">
                      <input
                        type="number"
                        name="hargaHardCoverTier5"
                        className="form-control"
                        value={this.state.hargaHardCoverTier5}
                        onChange={this.onChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col">Gambar 1</div>
                    <div className="col">Gambar 2</div>
                    <div className="col">Gambar 3</div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <input
                        type="file"
                        name="gambar1"
                        id="gambar1"
                        className="form-control"
                        onChange={this.onChangeFile}
                      />
                    </div>
                    <div className="col">
                      <input
                        type="file"
                        name="gambar2"
                        id="gambar2"
                        className="form-control"
                        onChange={this.onChangeFile}
                      />
                    </div>
                    <div className="col">
                      <input
                        type="file"
                        name="gambar3"
                        id="gambar3"
                        className="form-control"
                        onChange={this.onChangeFile}
                      />
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  disabled={this.props.product.loading}
                >
                  {this.props.product.loading ? (
                    <div
                      class="spinner-border spinner-border-sm text-light"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </form>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <div className="my-5 py-5">
          <div className="progress my-5">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
              aria-valuenow="100"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      );
    }
  }
}

EditProduct.propTypes = {
  getProductById: PropTypes.func.isRequired,
  getColors: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  updateProduct: PropTypes.func.isRequired,
  category: PropTypes.object.isRequired,
  color: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  category: state.category,
  color: state.color,
  product: state.product
});

export default connect(
  mapStateToProps,
  { getProductById, getColors, getCategories, updateProduct }
)(EditProduct);
