import {
  GET_GALLERY_PRODUCTS,
  GET_GALLERY_ARTICLES,
  POST_GALLERY_PRODUCTS,
  GET_ERRORS,
  POST_GALLERY_ARTICLES,
  LOADING_GALLERY
} from "./types";
import axios from "axios";
import { toast } from "react-toastify";

// Get Gallery Products
export const getGalleryProducts = () => dispatch => {
  axios
    .get("https://sandbox-api2.pentone.id/api/gallery/products")
    .then(res => dispatch({ type: GET_GALLERY_PRODUCTS, payload: res.data }))
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Get Gallery Articles
export const getGalleryArticles = () => dispatch => {
  axios
    .get("https://sandbox-api2.pentone.id/api/gallery/articles")
    .then(res => dispatch({ type: GET_GALLERY_ARTICLES, payload: res.data }))
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Post Gallery Products
export const postGalleryProducts = images => dispatch => {
  dispatch(setGalleryLoading(true));
  axios
    .post("https://sandbox-api2.pentone.id/api/gallery/products", images)
    .then(res => {
      dispatch({ type: POST_GALLERY_PRODUCTS, payload: res.data });
      toast.success("Gallery berhasil ditambahkan!");
    })
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Post Gallery Articles
export const postGalleryArticles = images => dispatch => {
  dispatch(setGalleryLoading(true));
  axios
    .post("https://sandbox-api2.pentone.id/api/gallery/articles", images)
    .then(res => {
      dispatch({ type: POST_GALLERY_ARTICLES, payload: res.data });
      toast.success("Gallery berhasil ditambahkan!");
    })
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const setGalleryLoading = loading => {
  return {
    type: LOADING_GALLERY,
    payload: loading
  };
};
