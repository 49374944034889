import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getArticle } from "../../actions/articleActions";
import ReactHtmlParser from "react-html-parser";
import isEmpty from "../../utils/is-empty";

class Article extends Component {
  componentDidMount() {
    if (!this.props.auth.isAdminAuthenticated) {
      this.props.history.push("/login");
    }

    if (isEmpty(this.props.article.article)) {
      this.props.history.push("/articles");
    }
  }
  render() {
    const { article } = this.props.article;

    if (!isEmpty(article)) {
      return (
        <React.Fragment>
          <img
            src={`https://sandbox-api2.pentone.id/assets/img/articles/${article.image}`}
            alt={article.image}
            className="img-fluid"
          />
          <table className="table table-strip">
            <tbody>
              <tr>
                <td>ID</td>
                <td>{article._id}</td>
              </tr>
              <tr>
                <td>Title</td>
                <td>{article.title}</td>
              </tr>
              <tr>
                <td>Author</td>
                <td>{article.author}</td>
              </tr>
              <tr>
                <td>Category</td>
                <td>{article.category}</td>
              </tr>
              <tr>
                <td>Date</td>
                <td>{article.date}</td>
              </tr>
              <tr>
                <td>Content</td>
                <td>{ReactHtmlParser(article.content)}</td>
              </tr>
            </tbody>
          </table>
        </React.Fragment>
      );
    } else {
      return (
        <div className="my-5 py-5">
          <div className="progress my-5">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
              aria-valuenow="100"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      );
    }
  }
}

Article.propTypes = {
  getArticle: PropTypes.func.isRequired,
  article: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  article: state.article
});

export default connect(
  mapStateToProps,
  { getArticle }
)(Article);
