import {
  GET_CATEGORIES,
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
  GET_ERRORS,
  GET_CATEGORY,
  LOADING_CATEGORY
} from "./types";
import axios from "axios";
import { toast } from "react-toastify";

// Get Categories
export const getCategories = () => dispatch => {
  axios
    .get("https://sandbox-api2.pentone.id/api/categories")
    .then(res => dispatch({ type: GET_CATEGORIES, payload: res.data }))
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Get Category by ID (WAIT)
export const getCategory = category => dispatch => {
  dispatch({ type: GET_CATEGORY, payload: category });
};

// Create New Category
export const createCategory = newCategory => dispatch => {
  dispatch(setCategoryLoading(true));
  axios
    .post("https://sandbox-api2.pentone.id/api/categories", newCategory)
    .then(res => {
      dispatch({ type: CREATE_CATEGORY, payload: res.data });
      toast.success("Category berhasil dibuat!");
    })
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Update Category by id
export const updateCategory = (newCategory, id) => dispatch => {
  dispatch(setCategoryLoading(true));
  axios
    .post(`https://sandbox-api2.pentone.id/api/categories/${id}`, newCategory)
    .then(res => {
      dispatch({ type: UPDATE_CATEGORY, payload: res.data });
      toast.success("Category berhasil diupdate!");
    })
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Delete Category by id
export const deleteCategory = id => dispatch => {
  dispatch(setCategoryLoading(true));
  axios
    .delete(`https://sandbox-api2.pentone.id/api/categories/${id}`)
    .then(res => {
      dispatch({ type: DELETE_CATEGORY, payload: res.data });
      toast.success("Category berhasil dihapus!");
    })
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const setCategoryLoading = loading => {
  return {
    type: LOADING_CATEGORY,
    payload: loading
  };
};
