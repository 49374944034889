import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getColors, deleteColor, getColor } from "../../actions/colorActions";
import { ToastContainer } from "react-toastify";

class Colors extends Component {
  componentDidMount() {
    if (!this.props.auth.isAdminAuthenticated) {
      this.props.history.push("/login");
    }
    this.props.getColors();
  }

  onDelete = id => {
    if (window.confirm("Are you sure want to delete this color?")) {
      this.props.deleteColor(id);
    }
  };

  onEdit = (color, e) => {
    this.props.getColor(color);
    this.props.history.push(`colors-edit/${color.name}`);
  };

  render() {
    const { colors } = this.props.color;

    if (colors.length > 0) {
      return (
        <React.Fragment>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover={false}
          />
          <Link className="btn btn-success" to="colors-create">
            Create New Color
          </Link>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Name</th>
                <th>Code</th>
                <th>Preview</th>
                <th>ID</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {colors.map(color => (
                <tr key={color._id}>
                  <td>{color.name}</td>
                  <td>{color.code}</td>
                  <td style={{ backgroundColor: color.code }} />
                  <td>{color._id}</td>
                  <td className="px-0">
                    <button
                      className="btn btn-sm btn-warning mx-0"
                      onClick={this.onEdit.bind(this, color)}
                    >
                      Edit
                    </button>
                  </td>
                  <td className="px-0">
                    <button
                      className="btn btn-sm btn-danger mx-0"
                      onClick={this.onDelete.bind(this, color._id)}
                      disabled={this.props.color.loading}
                    >
                      {this.props.color.loading ? (
                        <div
                          class="spinner-border spinner-border-sm text-light"
                          role="status"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                      ) : (
                        "Delete"
                      )}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </React.Fragment>
      );
    } else {
      return (
        <div className="my-5 py-5">
          <div className="progress my-5">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
              aria-valuenow="100"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      );
    }
  }
}

Colors.propTypes = {
  getColors: PropTypes.func.isRequired,
  deleteColor: PropTypes.func.isRequired,
  color: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  color: state.color
});

export default connect(
  mapStateToProps,
  { getColors, deleteColor, getColor }
)(Colors);
