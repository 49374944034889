import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createArticle } from "../../actions/articleActions";
import CKEditor from "react-ckeditor-component";
import { ToastContainer } from "react-toastify";

class CreateArticle extends Component {
  constructor() {
    super();
    this.state = {
      author: "",
      category: "",
      content: "",
      image: "",
      title: ""
    };
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeFile = e => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  onChangeCK = e => {
    this.setState({
      content: e.editor.getData()
    });
  };

  onSubmit = e => {
    e.preventDefault();

    const newArticle = new FormData();

    newArticle.append("title", this.state.title);
    newArticle.append("author", this.state.author);
    newArticle.append("category", this.state.category);
    newArticle.append("content", this.state.content);
    newArticle.append("image", this.state.image);

    this.props.createArticle(newArticle);
  };

  componentDidMount() {
    if (!this.props.auth.isAdminAuthenticated) {
      this.props.history.push("/login");
    }
  }

  render() {
    return (
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover={false}
        />
        <div className="card">
          <div className="card-header text-center">
            <h1 className="p-0 m-0">Edit Articles</h1>
          </div>
          <div className="card-body">
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                <label htmlFor="title">Title</label>
                <input
                  type="text"
                  name="title"
                  id="title"
                  placeholder="Enter Article Title"
                  className="form-control input-create"
                  value={this.state.title}
                  onChange={this.onChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="author">Author / Secondary Title</label>
                <input
                  type="text"
                  name="author"
                  id="author"
                  placeholder="Enter Article Author"
                  className="form-control input-create"
                  onChange={this.onChange}
                  value={this.state.author}
                />
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-md-2">
                    <input
                      type="radio"
                      name="category"
                      value="DIY"
                      id="DIY"
                      onChange={this.onChange}
                    />
                    <label htmlFor="DIY">DIY</label>
                  </div>
                  <div className="col-md-2">
                    <input
                      type="radio"
                      name="category"
                      value="Inspirasi"
                      id="Inspirasi"
                      onChange={this.onChange}
                    />
                    <label htmlFor="Inspirasi">Inspirasi</label>
                  </div>
                  <div className="col-md-2">
                    <input
                      type="radio"
                      name="category"
                      value="Tips"
                      id="Tips"
                      onChange={this.onChange}
                    />
                    <label htmlFor="Tips">Tips</label>
                  </div>
                  <div className="col-md-2">
                    <input
                      type="radio"
                      name="category"
                      value="Pemesanan"
                      id="Pemesanan"
                      onChange={this.onChange}
                    />
                    <label htmlFor="Pemesanan">Pemesanan</label>
                  </div>
                  <div className="col-md-2">
                    <input
                      type="radio"
                      name="category"
                      value="Pembayaran"
                      id="Pembayaran"
                      onChange={this.onChange}
                    />
                    <label htmlFor="Pembayaran">Pembayaran</label>
                  </div>
                  <div className="col-md-2">
                    <input
                      type="radio"
                      name="category"
                      value="LayananCustom"
                      id="LayananCustom"
                      onChange={this.onChange}
                    />
                    <label htmlFor="LayananCustom">LayananCustom</label>
                  </div>
                  <div className="col-md-2">
                    <input
                      type="radio"
                      name="category"
                      value="Akun"
                      id="Akun"
                      onChange={this.onChange}
                    />
                    <label htmlFor="Akun">Akun</label>
                  </div>
                  <div className="col-md-2">
                    <input
                      type="radio"
                      name="category"
                      value="Material"
                      id="Material"
                      onChange={this.onChange}
                    />
                    <label htmlFor="Material">Material</label>
                  </div>
                  <div className="col-md-2">
                    <input
                      type="radio"
                      name="category"
                      value="Umum"
                      id="Umum"
                      onChange={this.onChange}
                    />
                    <label htmlFor="Umum">Umum</label>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="content">Content</label>
                <CKEditor
                  name="content"
                  content={this.state.content}
                  events={{ change: this.onChangeCK }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="image">Image</label>
                <input
                  type="file"
                  className="form-control input-create"
                  name="image"
                  id="image"
                  onChange={this.onChangeFile}
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary btn-block"
                disabled={this.props.article.loading}
              >
                {this.props.article.loading ? (
                  <div
                    class="spinner-border spinner-border-sm text-light"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

CreateArticle.propTypes = {
  createArticle: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  article: state.article
});

export default connect(
  mapStateToProps,
  { createArticle }
)(CreateArticle);
