import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createColor } from "../../actions/colorActions";
import { ToastContainer } from "react-toastify";

class CreateColor extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      code: ""
    };
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const newColor = {};

    newColor.name = this.state.name;
    newColor.code = this.state.code;

    this.props.createColor(newColor);
  };

  componentDidMount() {
    if (!this.props.auth.isAdminAuthenticated) {
      this.props.history.push("/login");
    }
  }

  render() {
    return (
      <div className="card">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover={false}
        />
        <div className="card-header text-center">
          <h1 className="p-0 m-0">Create New Color</h1>
        </div>
        <div className="card-body">
          <div className="row">
            <div
              className="col-md-2 mx-auto text-center"
              style={{
                backgroundColor: this.state.code,
                border: "2px solid black"
              }}
            >
              Color Preview
            </div>
          </div>
          <form onSubmit={this.onSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                id="name"
                className="form-control input-create"
                placeholder="Enter Color Name"
                value={this.state.name}
                onChange={this.onChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="code">Code</label>
              <input
                type="text"
                name="code"
                id="code"
                className="form-control input-create"
                placeholder="Enter Color Code"
                value={this.state.code}
                onChange={this.onChange}
                required
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary btn-block input-create"
              disabled={this.props.color.loading}
            >
              {this.props.color.loading ? (
                <div
                  class="spinner-border spinner-border-sm text-light"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </form>
        </div>
      </div>
    );
  }
}

CreateColor.propTypes = {
  createColor: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  color: state.color
});

export default connect(
  mapStateToProps,
  { createColor }
)(CreateColor);
