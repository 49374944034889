import {
  GET_CATEGORIES,
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
  GET_ERRORS,
  GET_CATEGORY,
  LOADING_CATEGORY
} from "../actions/types";

const initialState = {
  categories: [],
  category: {},
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
        loading: false
      };
    case GET_CATEGORY:
      return {
        ...state,
        category: action.payload
      };
    case CREATE_CATEGORY:
      return {
        ...state,
        loading: false
      };
    case UPDATE_CATEGORY:
      return {
        ...state,
        category: action.payload,
        loading: false
      };
    case DELETE_CATEGORY:
      return {
        ...state,
        loading: false
      };
    case GET_ERRORS:
      return {
        ...state,
        loading: false
      };
    case LOADING_CATEGORY:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
};
