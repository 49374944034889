import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getCategories,
  deleteCategory,
  getCategory
} from "../../actions/categoryActions";
import { ToastContainer } from "react-toastify";

class Categories extends Component {
  componentDidMount() {
    if (!this.props.auth.isAdminAuthenticated) {
      this.props.history.push("/login");
    }
    this.props.getCategories();
  }

  onDelete = id => {
    if (window.confirm("Are you sure to delete this category?")) {
      this.props.deleteCategory(id);
    }
  };

  onEdit = (category, e) => {
    this.props.getCategory(category);
    this.props.history.push(`categories-edit/${category.name}`);
  };

  render() {
    const { categories } = this.props.category;
    if (categories.length > 0) {
      return (
        <React.Fragment>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover={false}
          />
          <Link className="btn btn-success" to="categories-create">
            Create New Category
          </Link>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Name</th>
                <th>ID</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {categories.map(category => (
                <tr key={category._id}>
                  <td>{category.name}</td>
                  <td>{category._id}</td>
                  <td className="px-0">
                    <button
                      className="btn btn-sm btn-warning mx-0"
                      onClick={this.onEdit.bind(this, category)}
                    >
                      Edit
                    </button>
                  </td>
                  <td className="px-0">
                    <button
                      className="btn btn-sm btn-danger mx-0"
                      onClick={this.onDelete.bind(this, category._id)}
                      disabled={this.props.category.loading}
                    >
                      {this.props.category.loading ? (
                        <div
                          class="spinner-border spinner-border-sm text-light"
                          role="status"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                      ) : (
                        "Delete"
                      )}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </React.Fragment>
      );
    } else {
      return (
        <div className="my-5 py-5">
          <div className="progress my-5">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
              aria-valuenow="100"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      );
    }
  }
}

Categories.propTypes = {
  getCategories: PropTypes.func.isRequired,
  deleteCategory: PropTypes.func.isRequired,
  category: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  category: state.category
});

export default connect(
  mapStateToProps,
  { getCategories, deleteCategory, getCategory }
)(Categories);
