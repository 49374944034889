import {
  GET_COLORS,
  GET_COLOR,
  GET_ERRORS,
  DELETE_COLOR,
  CREATE_COLOR,
  UPDATE_COLOR,
  LOADING_COLOR
} from "../actions/types";

const initialState = {
  colors: [],
  color: {},
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COLORS:
      return {
        ...state,
        colors: action.payload,
        loading: false
      };
    case GET_COLOR:
      return {
        ...state,
        color: action.payload
      };
    case CREATE_COLOR:
      return {
        ...state,
        loading: false
      };
    case UPDATE_COLOR:
      return {
        ...state,
        color: action.payload,
        loading: false
      };
    case DELETE_COLOR:
      return {
        ...state,
        loading: false
      };
    case GET_ERRORS:
      return { ...state, loading: false };
    case LOADING_COLOR:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
