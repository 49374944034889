import React, { Component } from "react";
import { connect } from "react-redux";
import { getStatistic } from "../../actions/userActions";
import moment from "moment";

export class Statistic extends Component {
  constructor() {
    super();
    this.state = {
      date_start: moment().subtract(30, "day"),
      date_end: moment().add(1, "day"),
    };
  }

  componentDidMount() {
    this.props.getStatistic();
  }

  checkBasicPrice = (category, total) => {
    let price = 0;
    if (category === "value" || category === "softCover") {
      if (total >= 1000) {
        price = 3000;
      } else if (total >= 900 && total <= 999) {
        price = 3050;
      } else if (total >= 800 && total <= 899) {
        price = 3100;
      } else if (total >= 700 && total <= 799) {
        price = 3200;
      } else if (total >= 600 && total <= 699) {
        price = 3300;
      } else if (total >= 500 && total <= 599) {
        price = 3500;
      } else if (total >= 400 && total <= 499) {
        price = 4200;
      } else if (total >= 300 && total <= 399) {
        price = 5000;
      }
    } else if (category === "popular") {
      if (total >= 1000) {
        price = 6200;
      } else if (total >= 900 && total <= 999) {
        price = 6250;
      } else if (total >= 800 && total <= 899) {
        price = 6300;
      } else if (total >= 700 && total <= 799) {
        price = 6400;
      } else if (total >= 600 && total <= 699) {
        price = 6450;
      } else if (total >= 500 && total <= 599) {
        price = 6550;
      } else if (total >= 400 && total <= 499) {
        price = 7800;
      } else if (total >= 300 && total <= 399) {
        price = 8000;
      }
    } else if (category === "premium") {
      if (total >= 1000) {
        price = 7200;
      } else if (total >= 900 && total <= 999) {
        price = 7250;
      } else if (total >= 800 && total <= 899) {
        price = 7300;
      } else if (total >= 700 && total <= 799) {
        price = 7450;
      } else if (total >= 600 && total <= 699) {
        price = 7500;
      } else if (total >= 500 && total <= 599) {
        price = 7600;
      } else if (total >= 400 && total <= 499) {
        price = 9400;
      } else if (total >= 300 && total <= 399) {
        price = 10500;
      }
    }

    return price;
  };

  checkPaperPrice = (category, paper) => {
    let price = 0;
    switch (category) {
      case "value":
        switch (paper) {
          case "260gr":
            price = 0;
            break;
          case "310gr":
            price = 400;
            break;
          default:
            break;
        }
        break;
      case "softCover":
        switch (paper) {
          case "260gr":
            price = 0;
            break;
          case "310gr":
            price = 400;
            break;
          default:
            break;
        }
        break;
      case "popular":
        switch (paper) {
          case "310gr":
            price = 0;
            break;
          case "620gr":
            price = 500;
            break;
          default:
            break;
        }
        break;
      case "premium":
        switch (paper) {
          case "Pentone Matte Paper":
            price = 0;
            break;
          case "Fancy Florida Akasia":
            price = 300;
            break;
          case "Pearlescent Paper":
            price = 300;
            break;
          case "Fancy Java":
            price = 300;
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }

    return price;
  };

  checkFinishingPrice = (finishing) => {
    let price = 0;
    switch (finishing) {
      case "Cetak Emas (Gold Foil)":
        price = 750;
        break;
      case "Cetak Peta Terpisah":
        price = 500;
        break;
      case "Ilustrasi Belakang Amplop":
        price = 1000;
        break;
      case "Cetak Timbul":
        price = 750;
        break;
      case "Gold Seal Emblem":
        price = 500;
        break;
      case "Paket Value":
        price = 1500;
        break;
      case "Paket Complete":
        price = 1900;
        break;
      case "Paket Best Offer":
        price = 2000;
        break;
      case "Wax Seal":
        price = 3500;
        break;
      default:
        break;
    }

    return price;
  };

  checkTotalFinishingPrice = (arrayFinishing) => {
    let price = 0;
    arrayFinishing.forEach((f) => {
      price += this.checkFinishingPrice(f);
    });

    return price;
  };

  checkFinalPriceNett = (user) => {
    let final = 0;

    final =
      final +
      this.checkBasicPrice(user.idOrder.idTipeUndangan, user.idOrder.quantity) *
        user.idOrder.quantity;
    final =
      final +
      this.checkPaperPrice(
        user.idOrder.idTipeUndangan,
        user.idOrder.idJenisKertas
      ) *
        user.idOrder.quantity;
    final =
      final +
      this.checkTotalFinishingPrice(user.idOrder.sentuhanAkhir) *
        user.idOrder.quantity;

    user.additionalItems.map((ai, i) => {
      final = final + ai.total * ai.quantity;
    });

    return final;
  };

  checkFinalPriceGross = (user) => {
    let final = 0;

    final =
      final +
      this.checkBasicPrice(user.idOrder.idTipeUndangan, user.idOrder.quantity) *
        user.idOrder.quantity;
    final =
      final +
      this.checkPaperPrice(
        user.idOrder.idTipeUndangan,
        user.idOrder.idJenisKertas
      ) *
        user.idOrder.quantity;
    final =
      final +
      this.checkTotalFinishingPrice(user.idOrder.sentuhanAkhir) *
        user.idOrder.quantity;

    user.additionalItems.map((ai, i) => {
      if (ai.total > 0) {
        final = final + ai.total * ai.quantity;
      }
    });

    return final;
  };

  renderStatistic() {
    let totalSalesNett = 0;
    let totalSalesGross = 0;
    let totalDiscount = 0;
    let totalInvitation = 0;
    let totalSoftCover = 0;
    let totalPopular = 0;
    let totalPremium = 0;
    let sentuhanAkhir = {};
    let totalData = [];
    this.props.user.users.forEach((user) => {
      // Filter Date
      if (
        moment(user.idOrder.statusDP.transaction_time).diff(
          this.state.date_start
        ) > 0 &&
        moment(user.idOrder.statusDP.transaction_time).diff(
          this.state.date_end
        ) < 0 &&
        user.idOrder.statusDP.status_code === "200"
      ) {
        // Process filter data
        totalData.push(user);

        // Accumulate sales
        totalSalesNett += this.checkFinalPriceNett(user);
        totalSalesGross += this.checkFinalPriceGross(user);

        // Accumulate discount
        user.additionalItems.forEach((ai) => {
          if (ai.total < 0) {
            totalDiscount += ai.total * ai.quantity;
          }
        });

        // Accumulate total invitation sold
        totalInvitation += user.idOrder.quantity;

        // Accumulate Category
        switch (user.idOrder.idTipeUndangan) {
          case "softCover":
            totalSoftCover += user.idOrder.quantity;
            break;
          case "value":
            totalSoftCover += user.idOrder.quantity;
            break;
          case "popular":
            totalPopular += user.idOrder.quantity;
            break;
          case "premium":
            totalPremium += user.idOrder.quantity;
            break;
          default:
            break;
        }

        // Accumulate sentuhan akhir
        user.idOrder.sentuhanAkhir.forEach((sa) => {
          if (sa[0] === " ") {
            sa = sa.trim(1);
          }

          if (sentuhanAkhir[sa] === undefined) {
            sentuhanAkhir[sa] = 0;
          }

          sentuhanAkhir[sa] += user.idOrder.quantity;
        });
      }
    });

    return (
      <React.Fragment>
        <h3>Sales</h3>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="">Sales Nett Total</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon2">
                    Rp{" "}
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  value={totalSalesNett.toLocaleString("IDN")}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="">Sales Nett Average</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon2">
                    Rp{" "}
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  value={(totalSalesNett / totalData.length).toLocaleString(
                    "IDN"
                  )}
                  disabled
                />
                <div class="input-group-append">
                  <span class="input-group-text">/ Order</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="">Sales Gross Total</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon2">
                    Rp{" "}
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  value={totalSalesGross.toLocaleString("IDN")}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="">Sales Gross Average</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon2">
                    Rp{" "}
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  value={(totalSalesGross / totalData.length).toLocaleString(
                    "IDN"
                  )}
                  disabled
                />
                <div class="input-group-append">
                  <span class="input-group-text">/ Order</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3>Order</h3>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="">Order Total</label>
              <div class="input-group">
                <input
                  type="text"
                  className="form-control"
                  value={totalData.length}
                  disabled
                />
                <div class="input-group-append">
                  <span class="input-group-text">Orders</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="">Order Average</label>
              <div class="input-group">
                <input
                  type="text"
                  className="form-control"
                  value={
                    totalData.length /
                    moment(this.state.date_end).diff(
                      moment(this.state.date_start),
                      "days"
                    )
                  }
                  disabled
                />
                <div class="input-group-append">
                  <span class="input-group-text">Order / day</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="">Order Average</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">1 Order /</span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  value={
                    1 /
                    (totalData.length /
                      moment(this.state.date_end).diff(
                        moment(this.state.date_start),
                        "days"
                      ))
                  }
                  disabled
                />
                <div class="input-group-append">
                  <span class="input-group-text">Day</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3>Discount</h3>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="">Discount Total</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">Rp</span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  value={(totalDiscount * -1).toLocaleString("IDN")}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="">Discount Average</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">Rp</span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  value={(
                    (totalDiscount * -1) /
                    totalData.length
                  ).toLocaleString("IDN")}
                  disabled
                />
                <div class="input-group-append">
                  <span class="input-group-text">/ Order</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3>Invitation</h3>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="">Invitation Total</label>
              <div class="input-group">
                <input
                  type="text"
                  className="form-control"
                  value={totalInvitation}
                  disabled
                />
                <div class="input-group-append">
                  <span class="input-group-text">Pcs</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="">Invitation Average</label>
              <div class="input-group">
                <input
                  type="text"
                  className="form-control"
                  value={totalInvitation / totalData.length}
                  disabled
                />
                <div class="input-group-append">
                  <span class="input-group-text">Pcs / Order</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3>Category</h3>
        <div className="row">
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="">Premium</label>
              <div class="input-group">
                <input
                  type="text"
                  className="form-control"
                  value={totalPremium.toLocaleString("IDN")}
                  disabled
                />
                <div class="input-group-append">
                  <span class="input-group-text">Pcs</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="">Popular</label>
              <div class="input-group">
                <input
                  type="text"
                  className="form-control"
                  value={totalPopular.toLocaleString("IDN")}
                  disabled
                />
                <div class="input-group-append">
                  <span class="input-group-text">Pcs</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="">Value</label>
              <div class="input-group">
                <input
                  type="text"
                  className="form-control"
                  value={totalSoftCover.toLocaleString("IDN")}
                  disabled
                />
                <div class="input-group-append">
                  <span class="input-group-text">Pcs</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3>Sentuhan Akhir</h3>
        <div className="row">
          {Object.entries(sentuhanAkhir).map(([key, value]) => {
            return (
              <div className="col-md-2" key={key}>
                <div className="form-group">
                  <label htmlFor="">{key}</label>
                  <div class="input-group">
                    <input
                      type="text"
                      className="form-control"
                      value={value.toLocaleString("IDN")}
                      disabled
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">Pcs</span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  }

  render() {
    if (this.props.user.loading) {
      return (
        <div className="text-center">
          <h4>Maaf agak lama soalnya data yang diproses banyak</h4>
          <div
            class="spinner-border"
            style={{ width: "3rem", height: "3rem" }}
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      );
    }
    return (
      <div>
        <h2>Statistic</h2>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="">Date Start</label>
              <input
                type="date"
                className="form-control"
                value={moment(this.state.date_start).format("YYYY-MM-DD")}
                onChange={(e) => {
                  this.setState({ date_start: moment(e.target.value) });
                }}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="">Date End</label>
              <input
                type="date"
                className="form-control"
                value={moment(this.state.date_end).format("YYYY-MM-DD")}
                onChange={(e) => {
                  this.setState({ date_end: moment(e.target.value) });
                }}
              />
            </div>
          </div>
        </div>
        <hr className="mb-4" />
        {this.renderStatistic()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
});

const mapDispatchToProps = {
  getStatistic,
};

export default connect(mapStateToProps, mapDispatchToProps)(Statistic);
