import {
  GET_DESIGNERS,
  CREATE_DESIGNER,
  DELETE_DESIGNER,
  UPDATE_DESIGNER,
  GET_ERRORS,
  GET_DESIGNER,
  LOADING_DESIGNER
} from "./types";
import axios from "axios";
import { toast } from "react-toastify";

// Get Designers
export const getDesigners = () => dispatch => {
  axios
    .get("https://sandbox-api2.pentone.id/api/designers")
    .then(res => dispatch({ type: GET_DESIGNERS, payload: res.data }))
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Get Designer by ID (WAIT)
export const getDesigner = designer => dispatch => {
  dispatch({ type: GET_DESIGNER, payload: designer });
};

// Create New Designer
export const createDesigner = newDesigner => dispatch => {
  dispatch(setDesignerLoading(true));
  axios
    .post("https://sandbox-api2.pentone.id/api/designers", newDesigner)
    .then(res => {
      dispatch({ type: CREATE_DESIGNER, payload: res.data });
      toast.success("Designer berhasil dibuat!");
    })
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Update Designer by id
export const updateDesigner = (newDesigner, id) => dispatch => {
  dispatch(setDesignerLoading(true));
  axios
    .post(`https://sandbox-api2.pentone.id/api/designers/${id}`, newDesigner)
    .then(res => {
      dispatch({ type: UPDATE_DESIGNER, payload: res.data });
      toast.success("Designer berhasil diupdate!");
    })
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Delete Designer by id
export const deleteDesigner = id => dispatch => {
  dispatch(setDesignerLoading(true));
  axios
    .delete(`https://sandbox-api2.pentone.id/api/designers/${id}`)
    .then(res => {
      dispatch({ type: DELETE_DESIGNER, payload: res.data });
      toast.success("Designer berhasil dihapus!");
    })
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const setDesignerLoading = loading => {
  return {
    type: LOADING_DESIGNER,
    payload: loading
  };
};
