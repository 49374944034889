import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getProduct } from "../../actions/productActions";
import isEmpty from "../../utils/is-empty";

class Product extends Component {
  componentDidMount() {
    if (!this.props.auth.isAdminAuthenticated) {
      this.props.history.push("/login");
    }

    if (isEmpty(this.props.product.product)) {
      this.props.history.push("/products");
    }
  }

  render() {
    const { product } = this.props.product;

    if (product.slug === this.props.match.params.slug) {
      let arrayColor = [];
      let arrayColorCategory = [];
      product.color.map(c => arrayColor.push(c.name));
      product.colorCategory.map(c => arrayColorCategory.push(c.name));
      return (
        <React.Fragment>
          <div className="row mb-3">
            <div className="col-md-3 mx-auto">
              <img
                src={`https://sandbox-api2.pentone.id/assets/img/products/${
                  product.image[0]
                }`}
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-md-3 mx-auto">
              <img
                src={`https://sandbox-api2.pentone.id/assets/img/products/${
                  product.image[1]
                }`}
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-md-3 mx-auto">
              <img
                src={`https://sandbox-api2.pentone.id/assets/img/products/${
                  product.image[2]
                }`}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
          <table className="table table-striped">
            <thead className="d-none">
              <tr>
                <th width="20%" />
                <th width="20%" />
                <th width="20%" />
                <th width="20%" />
                <th width="20%" />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="1">Name</td>
                <td colSpan="4">{product.name}</td>
              </tr>
              <tr>
                <td colSpan="1">Slug</td>
                <td colSpan="4">{product.slug}</td>
              </tr>
              <tr>
                <td colSpan="1">Designer</td>
                <td colSpan="4">{product.designer.name}</td>
              </tr>
              <tr>
                <td colSpan="1">Category</td>
                <td colSpan="4">{product.category.name}</td>
              </tr>
              <tr>
                <td colSpan="1">Set</td>
                <td colSpan="4">{product.set}</td>
              </tr>
              <tr>
                <td colSpan="1">Size</td>
                <td colSpan="4">{product.size}</td>
              </tr>
              <tr>
                <td colSpan="1">Printing</td>
                <td colSpan="4">{product.printing}</td>
              </tr>
              <tr>
                <td colSpan="1">Color</td>
                <td colSpan="4">{arrayColor.join()}</td>
              </tr>
              <tr>
                <td colSpan="1">Color Category</td>
                <td colSpan="4">{arrayColorCategory.join()}</td>
              </tr>
              <tr>
                <td colSpan="1">Description</td>
                <td colSpan="4">{product.description}</td>
              </tr>
              <tr>
                <td colSpan="5">Harga Soft Cover</td>
              </tr>
              <tr>
                <td>Tier 1</td>
                <td>Tier 2</td>
                <td>Tier 3</td>
                <td>Tier 4</td>
                <td>Tier 5</td>
              </tr>
              <tr>
                <td>{product.hargaSoftCoverTier1}</td>
                <td>{product.hargaSoftCoverTier2}</td>
                <td>{product.hargaSoftCoverTier3}</td>
                <td>{product.hargaSoftCoverTier4}</td>
                <td>{product.hargaSoftCoverTier5}</td>
              </tr>
              <tr>
                <td colSpan="5">Harga Hard Cover</td>
              </tr>
              <tr>
                <td>Tier 1</td>
                <td>Tier 2</td>
                <td>Tier 3</td>
                <td>Tier 4</td>
                <td>Tier 5</td>
              </tr>
              <tr>
                <td>{product.hargaHardCoverTier1}</td>
                <td>{product.hargaHardCoverTier2}</td>
                <td>{product.hargaHardCoverTier3}</td>
                <td>{product.hargaHardCoverTier4}</td>
                <td>{product.hargaHardCoverTier5}</td>
              </tr>
            </tbody>
          </table>
        </React.Fragment>
      );
    } else {
      return (
        <div className="my-5 py-5">
          <div className="progress my-5">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
              aria-valuenow="100"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      );
    }
  }
}

Product.propTypes = {
  getProduct: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  product: state.product
});

export default connect(
  mapStateToProps,
  { getProduct }
)(Product);
