import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { GET_ERRORS, SET_CURRENT_ADMIN } from "./types";

// Login Admin - Get Admin Token
export const loginAdmin = adminData => dispatch => {
  axios
    .post(`https://sandbox-api2.pentone.id/api/admin/login`, adminData)
    .then(res => {
      // Save to localStorage
      const { token } = res.data;
      // Set token to localStorage
      localStorage.setItem("jwtTokenAdmin", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get admin data
      const decoded = jwt_decode(token);
      // Set current admin
      dispatch(setCurrentAdmin(decoded));
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Set logged in admin
export const setCurrentAdmin = decoded => {
  return {
    type: SET_CURRENT_ADMIN,
    payload: decoded
  };
};

// Log user out
export const logoutAdmin = () => dispatch => {
  // Remove token from localStorage
  localStorage.removeItem("jwtTokenAdmin");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAdminAuthenticated to false
  dispatch(setCurrentAdmin({}));
};
