import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getWordings,
  deleteWording,
  getWording
} from "../../actions/wordingAction";
import { ToastContainer } from "react-toastify";

class Wordings extends Component {
  componentDidMount() {
    if (!this.props.auth.isAdminAuthenticated) {
      this.props.history.push("/login");
    }
    this.props.getWordings();
  }

  onDelete = id => {
    if (window.confirm("Are you sure want to delete this wording?")) {
      this.props.deleteWording(id);
    }
  };

  onEdit = (wording, e) => {
    this.props.getWording(wording);
    this.props.history.push(`wordings-edit/${wording.name}`);
  };

  render() {
    const { wordings } = this.props.wording;

    if (wordings.length > 0) {
      return (
        <React.Fragment>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover={false}
          />
          <Link className="btn btn-success" to="wordings-create">
            Create New Wording
          </Link>
          <table className="table table-striped">
            <thead>
              <tr>
                <th width="15%">Name</th>
                <th width="15%">Bahasa</th>
                <th width="15%">Agama</th>
                <th width="15%">Style</th>
                <th width="20%">Konten</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {wordings.map(wording => (
                <tr key={wording._id}>
                  <td>{wording.name}</td>
                  <td>{wording.bahasa}</td>
                  <td>{wording.agama}</td>
                  <td>{wording.style}</td>
                  <td>
                    <img
                      src={`https://sandbox-api2.pentone.id/assets/img/wordings/${
                        wording.konten
                      }`}
                      alt=""
                      className="img-fluid p-0 m-0"
                    />
                  </td>
                  <td className="px-0">
                    <button
                      className="btn btn-sm btn-warning mx-0"
                      onClick={this.onEdit.bind(this, wording)}
                    >
                      Edit
                    </button>
                  </td>
                  <td className="px-0">
                    <button
                      className="btn btn-sm btn-danger mx-0"
                      onClick={this.onDelete.bind(this, wording._id)}
                      disabled={this.props.wording.loading}
                    >
                      {this.props.wording.loading ? (
                        <div
                          class="spinner-border spinner-border-sm text-light"
                          role="status"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                      ) : (
                        "Delete"
                      )}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </React.Fragment>
      );
    } else {
      return (
        <div className="my-5 py-5">
          <div className="progress my-5">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
              aria-valuenow="100"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      );
    }
  }
}

Wordings.propTypes = {
  getWordings: PropTypes.func.isRequired,
  deleteWording: PropTypes.func.isRequired,
  wording: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  wording: state.wording
});

export default connect(
  mapStateToProps,
  { getWordings, deleteWording, getWording }
)(Wordings);
