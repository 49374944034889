import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createCategory } from "../../actions/categoryActions";
import { ToastContainer } from "react-toastify";

class CreateCategory extends Component {
  constructor() {
    super();
    this.state = {
      name: ""
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAdminAuthenticated) {
      this.props.history.push("/login");
    }
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    const newCategory = {};

    newCategory.name = this.state.name;

    this.props.createCategory(newCategory);
  };
  render() {
    return (
      <div className="card">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover={false}
        />
        <div className="card-header text-center">
          <h1 className="p-0 m-0">Create New Category</h1>
        </div>
        <div className="card-body">
          <form onSubmit={this.onSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Enter Category Name"
                className="form-control input-create"
                value={this.state.name}
                onChange={this.onChange}
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary btn-block input-create"
              disabled={this.props.category.loading}
            >
              {this.props.category.loading ? (
                <div
                  class="spinner-border spinner-border-sm text-light"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </form>
        </div>
      </div>
    );
  }
}

CreateCategory.propTypes = {
  createCategory: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  category: state.category
});

export default connect(
  mapStateToProps,
  { createCategory }
)(CreateCategory);
