import React from "react";
import { Link } from "react-router-dom";

export default function index() {
  return (
    <div className="row my-5">
      <div className="col-md-3">
        <Link className="btn btn-primary" to="gallery/products">
          Products Gallery
        </Link>
      </div>
      <div className="col-md-3">
        <Link className="btn btn-primary" to="gallery/articles">
          Articles Gallery
        </Link>
      </div>
      <div className="col-md-3">
        <Link className="btn btn-primary" to="gallery/products/add">
          Add New Product Images
        </Link>
      </div>
      <div className="col-md-3">
        <Link className="btn btn-primary" to="gallery/articles/add">
          Add New Article Images
        </Link>
      </div>
    </div>
  );
}
