import {
  GET_GALLERY_PRODUCTS,
  GET_ERRORS,
  GET_GALLERY_ARTICLES,
  POST_GALLERY_PRODUCTS,
  POST_GALLERY_ARTICLES,
  LOADING_GALLERY
} from "../actions/types";

const initialState = {
  products: [],
  articles: [],
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_GALLERY_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        loading: false
      };
    case GET_GALLERY_ARTICLES:
      return {
        ...state,
        articles: action.payload,
        loading: false
      };
    case POST_GALLERY_PRODUCTS:
      return {
        ...state,
        loading: false
      };
    case POST_GALLERY_ARTICLES:
      return {
        ...state,
        loading: false
      };
    case LOADING_GALLERY:
      return {
        ...state,
        loading: action.payload
      };
    case GET_ERRORS:
      return action.payload;
    default:
      return state;
  }
};
