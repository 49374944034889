import {
  GET_WORDINGS,
  GET_ERRORS,
  CREATE_WORDING,
  UPDATE_WORDING,
  DELETE_WORDING,
  GET_WORDING,
  LOADING_WORDING
} from "./types";
import axios from "axios";
import { toast } from "react-toastify";

// Get Colors
export const getWordings = () => dispatch => {
  axios
    .get("https://sandbox-api2.pentone.id/api/gayaPenulisan")
    .then(res => dispatch({ type: GET_WORDINGS, payload: res.data }))
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Get Color by ID (WAIT)
export const getWording = wording => dispatch => {
  dispatch({ type: GET_WORDING, payload: wording });
};

// Get Colors
export const createWording = newWording => dispatch => {
  dispatch(setWordingLoading(true));
  axios
    .post("https://sandbox-api2.pentone.id/api/gayaPenulisan", newWording)
    .then(res => {
      dispatch({ type: CREATE_WORDING, payload: res.data });
      toast.success("Wording berhasil dibuat!");
    })
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Update Color by id
export const updateWording = (newWording, id) => dispatch => {
  dispatch(setWordingLoading(true));
  axios
    .post(`https://sandbox-api2.pentone.id/api/gayaPenulisan/${id}`, newWording)
    .then(res => {
      dispatch({ type: UPDATE_WORDING, payload: res.data });
      toast.success("Wording berhasil diupdate!");
    })
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Delete Color by id
export const deleteWording = id => dispatch => {
  dispatch(setWordingLoading(true));
  axios
    .delete(`https://sandbox-api2.pentone.id/api/gayaPenulisan/${id}`)
    .then(res => {
      dispatch({ type: DELETE_WORDING, payload: res.data });
      toast.success("Wording berhasil dihapus!");
    })
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const setWordingLoading = loading => {
  return {
    type: LOADING_WORDING,
    payload: loading
  };
};
