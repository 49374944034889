import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutAdmin } from "../../actions/authActions";

class Navbar extends Component {
  onLogOut = () => {
    this.props.logoutAdmin();
    window.location = "/login";
  };

  render() {
    const { isAdminAuthenticated } = this.props.auth;

    const loginLinks = (
      <React.Fragment>
        <li className="nav-item">
          <Link to="/login" className="nav-link">
            Login
          </Link>
        </li>
      </React.Fragment>
    );

    const logoutLinks = (
      <React.Fragment>
        <li className="nav-item">
          <Link to="/" className="nav-link">
            Dashboard
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/products" className="nav-link">
            Products
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/articles" className="nav-link">
            Articles
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/categories" className="nav-link">
            Categories
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/colors" className="nav-link">
            Colors
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/gallery" className="nav-link">
            Gallery
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/wordings" className="nav-link">
            Wording
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/users" className="nav-link">
            Users
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/designers" className="nav-link">
            Designers
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/statistic" className="nav-link">
            Statistic
          </Link>
        </li>
        <li className="nav-item">
          <button className="nav-link btn btn-primary" onClick={this.onLogOut}>
            Logout
          </button>
        </li>
      </React.Fragment>
    );

    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container">
          <Link to="/" className="navbar-brand">
            Pentone
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="toggleNavbar"
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav ml-auto">
              {isAdminAuthenticated ? logoutLinks : loginLinks}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

Navbar.propTypes = {
  logoutAdmin: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutAdmin })(Navbar);
