import {
  GET_DESIGNERS,
  CREATE_DESIGNER,
  DELETE_DESIGNER,
  UPDATE_DESIGNER,
  GET_ERRORS,
  GET_DESIGNER,
  LOADING_DESIGNER
} from "../actions/types";

const initialState = {
  designers: [],
  designer: {},
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DESIGNERS:
      return {
        ...state,
        designers: action.payload,
        loading: false
      };
    case GET_DESIGNER:
      return {
        ...state,
        designer: action.payload
      };
    case CREATE_DESIGNER:
      return {
        ...state,
        loading: false
      };
    case UPDATE_DESIGNER:
      return {
        ...state,
        designer: action.payload,
        loading: false
      };
    case DELETE_DESIGNER:
      return {
        ...state,
        loading: false
      };
    case GET_ERRORS:
      return {
        ...state,
        loading: false
      };
    case LOADING_DESIGNER:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
};
