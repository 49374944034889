import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getArticle, updateArticle } from "../../actions/articleActions";
import CKEditor from "react-ckeditor-component";
import isEmpty from "../../utils/is-empty";
import { ToastContainer } from "react-toastify";

class EditArticle extends Component {
  constructor() {
    super();
    this.state = {
      author: "",
      category: "",
      content: "",
      image: "",
      title: ""
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAdminAuthenticated) {
      this.props.history.push("/login");
    }

    if (isEmpty(this.props.article.article)) {
      window.location = "/articles";
    }

    const { article } = this.props.article;
    this.setState({
      author: article.author,
      category: article.category,
      content: article.content,
      image: article.image,
      title: article.title
    });
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeFile = e => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  onChangeCK = e => {
    this.setState({
      content: e.editor.getData()
    });
  };

  onSubmit = e => {
    e.preventDefault();

    const updateArticle = new FormData();

    updateArticle.append("title", this.state.title);
    updateArticle.append("author", this.state.author);
    updateArticle.append("category", this.state.category);
    updateArticle.append("content", this.state.content);
    updateArticle.append("image", this.state.image);

    this.props.updateArticle(updateArticle, this.props.article.article._id);
  };

  render() {
    const { article } = this.props.article;
    if (!isEmpty(article)) {
      return (
        <React.Fragment>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover={false}
          />
          <img
            src={`https://sandbox-api2.pentone.id/assets/img/articles/${article.image}`}
            alt={article.image}
            className="img-fluid"
          />
          <div className="card">
            <form onSubmit={this.onSubmit}>
              <div className="card-header">Edit Articles</div>
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="title">Title</label>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    placeholder="Enter Article Title"
                    className="form-control input-create"
                    value={this.state.title}
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="author">Secondary Title</label>
                  <input
                    type="text"
                    name="author"
                    id="author"
                    placeholder="Enter Article Author"
                    className="form-control input-create"
                    onChange={this.onChange}
                    value={this.state.author}
                  />
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-2">
                      <input
                        type="radio"
                        name="category"
                        value="DIY"
                        id="DIY"
                        onChange={this.onChange}
                        checked={this.state.category === "DIY" ? true : false}
                      />
                      <label htmlFor="DIY">DIY</label>
                    </div>
                    <div className="col-md-2">
                      <input
                        type="radio"
                        name="category"
                        value="Inspirasi"
                        id="Inspirasi"
                        onChange={this.onChange}
                        className="input-create"
                        checked={
                          this.state.category === "Inspirasi" ? true : false
                        }
                      />
                      <label htmlFor="Inspirasi">Inspirasi</label>
                    </div>
                    <div className="col-md-2">
                      <input
                        type="radio"
                        name="category"
                        value="Tips"
                        id="Tips"
                        className="input-create"
                        onChange={this.onChange}
                        checked={this.state.category === "Tips" ? true : false}
                      />
                      <label htmlFor="Tips">Tips</label>
                    </div>
                    <div className="col-md-2">
                      <input
                        type="radio"
                        name="category"
                        value="Pemesanan"
                        id="Pemesanan"
                        className="input-create"
                        onChange={this.onChange}
                        checked={
                          this.state.category === "Pemesanan" ? true : false
                        }
                      />
                      <label htmlFor="Pemesanan">Pemesanan</label>
                    </div>
                    <div className="col-md-2">
                      <input
                        type="radio"
                        name="category"
                        value="Pembayaran"
                        id="Pembayaran"
                        className="input-create"
                        onChange={this.onChange}
                        checked={
                          this.state.category === "Pembayaran" ? true : false
                        }
                      />
                      <label htmlFor="Pembayaran">Pembayaran</label>
                    </div>
                    <div className="col-md-2">
                      <input
                        type="radio"
                        name="category"
                        value="LayananCustom"
                        id="LayananCustom"
                        className="input-create"
                        onChange={this.onChange}
                        checked={
                          this.state.category === "LayananCustom" ? true : false
                        }
                      />
                      <label htmlFor="LayananCustom">LayananCustom</label>
                    </div>
                    <div className="col-md-2">
                      <input
                        type="radio"
                        name="category"
                        value="Akun"
                        id="Akun"
                        className="input-create"
                        onChange={this.onChange}
                        checked={this.state.category === "Akun" ? true : false}
                      />
                      <label htmlFor="Akun">Akun</label>
                    </div>
                    <div className="col-md-2">
                      <input
                        type="radio"
                        name="category"
                        value="Material"
                        id="Material"
                        className="input-create"
                        onChange={this.onChange}
                        checked={
                          this.state.category === "Material" ? true : false
                        }
                      />
                      <label htmlFor="Material">Material</label>
                    </div>
                    <div className="col-md-2">
                      <input
                        type="radio"
                        name="category"
                        value="Umum"
                        id="Umum"
                        className="input-create"
                        onChange={this.onChange}
                        checked={this.state.category === "Umum" ? true : false}
                      />
                      <label htmlFor="Umum">Umum</label>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="content">Content</label>
                  <CKEditor
                    name="content"
                    content={this.state.content}
                    events={{ change: this.onChangeCK }}
                    activeClass="input-create"
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-primary btn-block input-create"
                  disabled={this.props.article.loading}
                >
                  {this.props.article.loading ? (
                    <div
                      class="spinner-border spinner-border-sm text-light"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <div className="my-5 py-5">
          <div className="progress my-5">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
              aria-valuenow="100"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      );
    }
  }
}

EditArticle.propTypes = {
  getArticle: PropTypes.func.isRequired,
  article: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  article: state.article
});

export default connect(
  mapStateToProps,
  { getArticle, updateArticle }
)(EditArticle);
