import { combineReducers } from "redux";
import productReducer from "./productReducer";
import colorReducer from "./colorReducer";
import categoryReducer from "./categoryReducer";
import articleReducer from "./articleReducer";
import authReducer from "./authReducer";
import galleryReducer from "./galleryReducer";
import userReducer from "./userReducer";
import errorReducer from "./errorReducer";
import wordingReducer from "./wordingReducer";
import designerReducer from "./designerReducer";
import additionalItemReducer from "./additionalItemReducer";

export default combineReducers({
  product: productReducer,
  color: colorReducer,
  category: categoryReducer,
  article: articleReducer,
  auth: authReducer,
  gallery: galleryReducer,
  user: userReducer,
  error: errorReducer,
  wording: wordingReducer,
  designer: designerReducer,
  additionalItem: additionalItemReducer
});
