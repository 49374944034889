import {
  GET_PRODUCTS,
  GET_ERRORS,
  GET_PRODUCT,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  LOADING_PRODUCT
} from "./types";
import axios from "axios";
import { toast } from "react-toastify";

// Get Products
export const getProducts = () => dispatch => {
  dispatch(setProductLoading(true));
  axios
    .get(`https://sandbox-api2.pentone.id/api/products/admin/all`)
    .then(res => dispatch({ type: GET_PRODUCTS, payload: res.data }))
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Get Product by slug
export const getProduct = product => dispatch => {
  dispatch({ type: GET_PRODUCT, payload: product });
};

// Get Product by id
export const getProductById = id => dispatch => {
  dispatch(setProductLoading(true));
  axios
    .get(`https://sandbox-api2.pentone.id/api/products/${id}`)
    .then(res => dispatch({ type: GET_PRODUCT, payload: res.data }))
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Create New Product
export const createProduct = newProduct => dispatch => {
  dispatch(setProductLoading(true));
  axios
    .post(`https://sandbox-api2.pentone.id/api/products`, newProduct)
    .then(res => {
      dispatch({ type: CREATE_PRODUCT, payload: res.data });
      toast.success("Produk berhasil ditambahkan!");
    })
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Update Product
export const updateProduct = (newProduct, id) => dispatch => {
  dispatch(setProductLoading(true));
  axios
    .post(`https://sandbox-api2.pentone.id/api/products/${id}`, newProduct)
    .then(res => {
      dispatch({ type: UPDATE_PRODUCT, payload: res.data });
      toast.success("Produk berhasil diupdate!");
    })
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Delete Product by Id
export const deleteProduct = id => dispatch => {
  dispatch(setProductLoading(true));
  axios
    .delete(`https://sandbox-api2.pentone.id/api/products/${id}`)
    .then(res => dispatch({ type: DELETE_PRODUCT, payload: res.data }))
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
  toast.success("Produk Berhasil dihapus!");
};

export const setProductLoading = loading => {
  return {
    type: LOADING_PRODUCT,
    payload: loading
  };
};

export const refreshError = () => dispatch => {
  dispatch({ type: GET_ERRORS, payload: {} });
};
