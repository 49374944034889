import React, { Component } from "react";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import { createDesigner } from "../../actions/designerActions";

class CreateDesigner extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      description: "",
      avatar: ""
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAdminAuthenticated) {
      this.props.history.push("/login");
    }
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeFile = e => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  onSubmit = e => {
    e.preventDefault();

    const newDesigner = new FormData();

    newDesigner.append("name", this.state.name);
    newDesigner.append("description", this.state.description);
    newDesigner.append("avatar", this.state.avatar);

    this.props.createDesigner(newDesigner);
  };

  render() {
    return (
      <div className="card">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover={false}
        />
        <div className="card-header text-center">
          <h1 className="p-0 m-0">Create New Designer</h1>
        </div>
        <div className="card-body">
          <form onSubmit={this.onSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Enter Category Name"
                className="form-control"
                value={this.state.name}
                onChange={this.onChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="name">Description</label>
              <input
                type="text"
                name="description"
                className="form-control"
                value={this.state.description}
                onChange={this.onChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="name">Avatar</label>
              <input
                type="file"
                name="avatar"
                className="form-control"
                onChange={this.onChangeFile}
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={this.props.designer.loading}
            >
              {this.props.designer.loading ? (
                <div
                  class="spinner-border spinner-border-sm text-light"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  designer: state.designer
});

export default connect(
  mapStateToProps,
  { createDesigner }
)(CreateDesigner);
