import {
  GET_ADDITIONAL_ITEMS,
  CREATE_ADDITIONAL_ITEM,
  DELETE_ADDITIONAL_ITEM,
  UPDATE_ADDITIONAL_ITEM,
  GET_ERRORS,
  GET_ADDITIONAL_ITEM,
  LOADING_ADDITIONAL_ITEM
} from "../actions/types";

const initialState = {
  additionalItems: [],
  additionalItem: {},
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ADDITIONAL_ITEMS:
      return {
        ...state,
        additionalItems: action.payload,
        loading: false
      };
    case GET_ADDITIONAL_ITEM:
      return {
        ...state,
        additionalItem: action.payload
      };
    case CREATE_ADDITIONAL_ITEM:
      return {
        ...state,
        loading: false
      };
    case UPDATE_ADDITIONAL_ITEM:
      return {
        ...state,
        additionalItem: action.payload,
        loading: false
      };
    case DELETE_ADDITIONAL_ITEM:
      return {
        ...state,
        loading: false
      };
    case GET_ERRORS:
      return {
        ...state,
        loading: false
      };
    case LOADING_ADDITIONAL_ITEM:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
};
