import React, { Component } from "react";
import { connect } from "react-redux";
import { getGalleryProducts } from "../../actions/galleryActions";

class galleryProducts extends Component {
  constructor() {
    super();
    this.state = {
      mode: "table",
      keyword: ""
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAdminAuthenticated) {
      this.props.history.push("/login");
    }
    this.props.getGalleryProducts();
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  changeView = () => {
    if (this.state.mode === "table") {
      this.setState({ mode: "image" });
    } else {
      this.setState({ mode: "table" });
    }
  };
  render() {
    const { products } = this.props.gallery;
    let i = 0;

    if (products.length > 0) {
      if (this.state.mode === "table") {
        return (
          <React.Fragment>
            <button className="btn btn-primary" onClick={this.changeView}>
              Change View
            </button>
            <input
              type="text"
              name="keyword"
              id="keyword"
              className="form-control"
              placeholder="Search..."
              value={this.state.keyword}
              onChange={this.onChange}
            />
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>No</th>
                  <th>File Name</th>
                  <th>Link</th>
                </tr>
              </thead>
              <tbody>
                {products.map(product => {
                  if (new RegExp(this.state.keyword, "gi").test(product)) {
                    return (
                      <tr key={i++}>
                        <td>{i}</td>
                        <td>{product}</td>
                        <td>
                          <a
                            href={`https://www.sandbox-api2.pentone.id/assets/img/products/${product}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >{`https://www.sandbox-api2.pentone.id/assets/img/products/${product}`}</a>
                        </td>
                      </tr>
                    );
                  } else {
                    return null;
                  }
                })}
              </tbody>
            </table>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <button className="btn btn-primary" onClick={this.changeView}>
              Change View
            </button>
            <input
              type="text"
              name="keyword"
              id="keyword"
              className="form-control"
              placeholder="Search..."
              value={this.state.keyword}
              onChange={this.onChange}
            />
            <div className="row">
              {products.map(product => {
                if (new RegExp(this.state.keyword, "gi").test(product)) {
                  return (
                    <div className="col-md-2 mx-auto" key={i++}>
                      <img
                        src={`https://sandbox-api2.pentone.id/assets/img/products/${product}`}
                        alt=""
                        className="img-fluid"
                      />
                      <p>{product}</p>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </React.Fragment>
        );
      }
    } else {
      return <h1>wait a sec</h1>;
    }
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  gallery: state.gallery
});

export default connect(
  mapStateToProps,
  { getGalleryProducts }
)(galleryProducts);
