import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getArticles,
  deleteArticle,
  getArticlesByCategory,
  getArticle
} from "../../actions/articleActions";
import { ToastContainer } from "react-toastify";

class Articles extends Component {
  onDelete = id => {
    if (window.confirm("Are you sure want to delete this article?")) {
      this.props.deleteArticle(id);
      this.props.getArticles();
    }
  };

  clickBantuan = category => {
    this.props.article.articles = [];
    this.props.getArticlesByCategory(category);
  };

  componentDidMount() {
    if (!this.props.auth.isAdminAuthenticated) {
      this.props.history.push("/login");
    }
    this.props.getArticles();
  }

  onView = (article, e) => {
    this.props.getArticle(article);
    this.props.history.push(`articles/${article.slug}`);
  };

  onEdit = (article, e) => {
    this.props.getArticle(article);
    this.props.history.push(`articles-edit/${article.slug}`);
  };

  render() {
    const { articles } = this.props.article;
    if (articles.length > 0) {
      return (
        <div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover={false}
          />
          <Link to="/articles-create" className="btn btn-success">
            Create New Article
          </Link>
          <button
            className="btn btn-info"
            onClick={this.clickBantuan.bind(this, "all")}
          >
            Articles
          </button>
          <button
            className="btn btn-warning"
            onClick={this.clickBantuan.bind(this, "Pemesanan")}
          >
            Pemesanan
          </button>
          <button
            className="btn btn-warning"
            onClick={this.clickBantuan.bind(this, "Pembayaran")}
          >
            Pembayaran
          </button>
          <button
            className="btn btn-warning"
            onClick={this.clickBantuan.bind(this, "LayananCustom")}
          >
            Layanan Custom
          </button>
          <button
            className="btn btn-warning"
            onClick={this.clickBantuan.bind(this, "Akun")}
          >
            Akun
          </button>
          <button
            className="btn btn-warning"
            onClick={this.clickBantuan.bind(this, "Material")}
          >
            Material
          </button>
          <button
            className="btn btn-warning"
            onClick={this.clickBantuan.bind(this, "Umum")}
          >
            Umum
          </button>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Title</th>
                <th>Author</th>
                <th>Date</th>
                <th>Image</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {articles.map(a => (
                <tr key={a._id}>
                  <td>{a.title}</td>
                  <td>{a.author}</td>
                  <td>{a.date}</td>
                  <td className="m-0 py-1">
                    <img
                      src={`https://sandbox-api2.pentone.id/assets/img/articles/${
                        a.image
                      }`}
                      className="img-fluid"
                      width="100px"
                      alt=""
                    />
                  </td>
                  <td className="px-0">
                    <button
                      onClick={this.onView.bind(this, a)}
                      className="btn btn-sm mx-0 btn-info"
                    >
                      View
                    </button>
                  </td>
                  <td className="px-0">
                    <button
                      onClick={this.onEdit.bind(this, a)}
                      className="btn btn-sm mx-0 btn-warning"
                    >
                      Edit
                    </button>
                  </td>
                  <td className="px-0">
                    <button
                      onClick={this.onDelete.bind(this, a._id)}
                      className="btn btn-sm mx-0 btn-danger"
                      disabled={this.props.article.loading}
                    >
                      {this.props.article.loading ? (
                        <div
                          class="spinner-border spinner-border-sm text-light"
                          role="status"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                      ) : (
                        "Delete"
                      )}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <Link to="/articles-create" className="btn btn-success">
            Create New Article
          </Link>
          <button
            className="btn btn-info"
            onClick={this.clickBantuan.bind(this, "all")}
          >
            Articles
          </button>
          <button
            className="btn btn-warning"
            onClick={this.clickBantuan.bind(this, "Pemesanan")}
          >
            Pemesanan
          </button>
          <button
            className="btn btn-warning"
            onClick={this.clickBantuan.bind(this, "Pembayaran")}
          >
            Pembayaran
          </button>
          <button
            className="btn btn-warning"
            onClick={this.clickBantuan.bind(this, "LayananCustom")}
          >
            Layanan Custom
          </button>
          <button
            className="btn btn-warning"
            onClick={this.clickBantuan.bind(this, "Akun")}
          >
            Akun
          </button>
          <button
            className="btn btn-warning"
            onClick={this.clickBantuan.bind(this, "Material")}
          >
            Material
          </button>
          <button
            className="btn btn-warning"
            onClick={this.clickBantuan.bind(this, "Umum")}
          >
            Umum
          </button>
          <div className="my-5 py-5">
            <div className="progress my-5">
              <div
                className="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                aria-valuemin="0"
                aria-valuemax="100"
                aria-valuenow="100"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

Articles.propTypes = {
  getArticles: PropTypes.func.isRequired,
  article: PropTypes.object.isRequired,
  deleteArticle: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  article: state.article,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getArticles, deleteArticle, getArticlesByCategory, getArticle }
)(Articles);
