import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCategory, updateCategory } from "../../actions/categoryActions";
import { ToastContainer } from "react-toastify";
import isEmpty from "../../utils/is-empty";

class EditCategory extends Component {
  constructor() {
    super();
    this.state = {
      name: ""
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAdminAuthenticated) {
      this.props.history.push("/login");
    }

    if (isEmpty(this.props.category.category)) {
      window.location = "/categories";
    }

    this.setState({ name: this.props.category.category.name });
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const newCategory = {};

    newCategory.name = this.state.name;

    this.props.updateCategory(newCategory, this.props.category.category._id);
  };

  render() {
    const { category } = this.props.category;

    if (!isEmpty(category)) {
      return (
        <div className="card">
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover={false}
          />
          <div className="card-header text-center">
            <h1 className="p-0 m-0">Edit Category</h1>
          </div>
          <div className="card-body">
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  className="form-control input-create"
                  id="name"
                  name="name"
                  placeholder="Enter Category Name"
                  value={this.state.name}
                  onChange={this.onChange}
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary btn-block"
                disabled={this.props.category.loading}
              >
                {this.props.category.loading ? (
                  <div
                    class="spinner-border spinner-border-sm text-light"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
        </div>
      );
    } else {
      return (
        <div className="my-5 py-5">
          <div className="progress my-5">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
              aria-valuenow="100"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      );
    }
  }
}

EditCategory.propTypes = {
  getCategory: PropTypes.func.isRequired,
  updateCategory: PropTypes.func.isRequired,
  category: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  category: state.category
});

export default connect(
  mapStateToProps,
  { getCategory, updateCategory }
)(EditCategory);
