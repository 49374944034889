import {
  GET_WORDINGS,
  GET_WORDING,
  GET_ERRORS,
  DELETE_WORDING,
  CREATE_WORDING,
  UPDATE_WORDING,
  LOADING_WORDING
} from "../actions/types";

const initialState = {
  wordings: [],
  wording: {},
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_WORDINGS:
      return {
        ...state,
        wordings: action.payload,
        loading: false
      };
    case GET_WORDING:
      return {
        ...state,
        wording: action.payload
      };
    case CREATE_WORDING:
      return {
        ...state,
        loading: false
      };
    case UPDATE_WORDING:
      return {
        ...state,
        wording: action.payload,
        loading: false
      };
    case DELETE_WORDING:
      return {
        ...state,
        loading: false
      };
    case GET_ERRORS:
      return { ...state, loading: false };
    case LOADING_WORDING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
