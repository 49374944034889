import {
  GET_ADDITIONAL_ITEMS,
  GET_ADDITIONAL_ITEM,
  GET_ERRORS,
  CREATE_ADDITIONAL_ITEM,
  UPDATE_ADDITIONAL_ITEM,
  DELETE_ADDITIONAL_ITEM,
  LOADING_ADDITIONAL_ITEM
} from "./types";
import axios from "axios";
import { toast } from "react-toastify";

// Get AdditionalItems
export const getAdditionalItems = () => dispatch => {
  dispatch(setAdditionalItemLoading(true));
  axios
    .get("https://sandbox-api2.pentone.id/api/additionalItems")
    .then(res => {
      dispatch({ type: GET_ADDITIONAL_ITEMS, payload: res.data });
    })
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Get AdditionalItems by idOrder
export const getAdditionalItemByIdOrder = idOrder => dispatch => {
  dispatch(setAdditionalItemLoading(true));
  axios
    .get(
      `https://sandbox-api2.pentone.id/api/additionalItems?idOrder=${idOrder}`
    )
    .then(res => {
      dispatch({ type: GET_ADDITIONAL_ITEMS, payload: res.data });
    })
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Get AdditionalItem by id
export const getAdditionalItemsByCategory = id => dispatch => {
  dispatch(setAdditionalItemLoading(true));
  axios
    .get(`https://sandbox-api2.pentone.id/api/additionalItems/${id}`)
    .then(res => dispatch({ type: GET_ADDITIONAL_ITEM, payload: res.data }))
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Create new AdditionalItem
export const createAdditionalItem = newAdditionalItem => dispatch => {
  dispatch(setAdditionalItemLoading(true));
  axios
    .post(
      "https://sandbox-api2.pentone.id/api/additionalItems",
      newAdditionalItem
    )
    .then(res => {
      dispatch({ type: CREATE_ADDITIONAL_ITEM, payload: res.data });
      toast.success("AdditionalItem berhasil dibuat!");
    })
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Update AdditionalItem by id
export const updateAdditionalItem = (newAdditionalItem, id) => dispatch => {
  dispatch(setAdditionalItemLoading(true));
  axios
    .post(
      `https://sandbox-api2.pentone.id/api/additionalItems/${id}`,
      newAdditionalItem
    )
    .then(res => {
      dispatch({ type: UPDATE_ADDITIONAL_ITEM, payload: res.data });
      toast.success("AdditionalItem berhasil diperbarui!");
    })
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Delete AdditionalItem by id
export const deleteAdditionalItem = id => dispatch => {
  dispatch(setAdditionalItemLoading(true));
  axios
    .delete(`https://sandbox-api2.pentone.id/api/additionalItems/${id}`)
    .then(res => {
      dispatch({ type: DELETE_ADDITIONAL_ITEM, payload: res.data });
      toast.success("AdditionalItem berhasil dihapus!");
    })
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

export const setAdditionalItemLoading = loading => {
  return {
    type: LOADING_ADDITIONAL_ITEM,
    payload: loading
  };
};
